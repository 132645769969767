import {getUtcDate, getWeek} from "../dateUtils";
import dayjs from 'dayjs';

export const WEEKS_AMOUNT = 4
export const currentWeek = getWeek(new Date())
export const dateFormat = 'DD.MM.YYYY'

export const getRepeatDay = (date) => {
    let days = ['воскресеньям', 'понедельникам', 'вторникам', 'средам', 'четвергам', 'пятницам', 'субботам']
    return days[date.getDay()]
}

export const getRepeatMonth = (date) => {
    let dates = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
    return dates[date.getMonth()]
}

export const getRepeatMessage = (repeatFrom, weekFilters, repeatTo) => {
    let repeatDay = ''
    if (repeatFrom) repeatDay = 'по ' + getRepeatDay(repeatFrom)

    let repeatWeek = ''
    let countCheckedWeek = weekFilters.filter(week => week === true).length
    if (countCheckedWeek !== 0 && countCheckedWeek === 1) repeatWeek = ' каждую ' + (weekFilters.findIndex(week => week === true) + 1) + '-ю неделю'
    else if (countCheckedWeek !== 0) repeatWeek = ' по ' + weekFilters.map((week, ind) => ({ind: ind, isSelect: week})).filter(week => week.isSelect === true).map((week) => week.ind + 1) + ' неделям'

    let repeatToTmp = ''
    if (repeatTo) repeatToTmp = ' до ' + repeatTo.getDate() + ' ' + getRepeatMonth(repeatTo)

    return repeatDay + repeatWeek + repeatToTmp
}

export const getRepeatWeeks = (repeatDates) => {
    let weekFiltersTmp = Array(4).fill(false)
    for (const date of repeatDates) {
        let week = getWeek(new Date(date))
        weekFiltersTmp[week] = true
    }
    return weekFiltersTmp
}

export const getRepeatDates = (repeatFromStr, repeatToStr, weekFilters) => {
    let dates = []
    let repeatFromTmp = getUtcDate(repeatFromStr).toDate()
    let repeatToTmp = getUtcDate(repeatToStr).toDate()
    while (true) {
        if (repeatFromTmp > repeatToTmp) break
        let week = getWeek(repeatFromTmp)
        if (weekFilters[week]) dates.push(new Date(repeatFromTmp))
        repeatFromTmp.setDate(repeatFromTmp.getDate() + 7)
    }
    return dates
}

export const getFilteredRepeatDates = (repeatGroup, announcements) => {
    return announcements.filter(an => an.repeatGroup === repeatGroup).sort((a, b) => a.date > b.date ? 1 : -1).map(an => an.date)
}

export const getRepeatFromForMessage = (repeatFrom, date) => {
    let repeatFromTmp = null
    if (repeatFrom) repeatFromTmp = dayjs(repeatFrom, dateFormat).toDate()
    else if (date) repeatFromTmp = dayjs(date, dateFormat).toDate()
    return repeatFromTmp
}

export const getRepeatToForMessage = (repeatTo) => {
    let repeatToTmp = null
    if (repeatTo) repeatToTmp = dayjs(repeatTo, dateFormat).toDate()
    return repeatToTmp
}