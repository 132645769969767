import JournalTable from "../JournalTable/JournalTable";
import TableViewButton from "../../TableViewButton/TableViewButton";
import { useDispatch, useSelector } from "react-redux";
import {
    editLabCount,
} from "../../../../store/actions/gradeBook";
import React, {useRef, useState} from "react";
import { Button, Checkbox, InputNumber, Popover, Segmented, Space } from "antd";
import classes from './JournalPanel.module.scss';
import {
    getMonthExpirationDate,
} from "../../../../services/dateUtils";
import Spinner from "../../../UI/Spinner/Spinner";
import FiltersWait from '../../../../assets/images/filtersWait.png';
import { cloneDeep } from "lodash";
import { getLabMessage } from "../../../../services/Gradebook/gradeBookService";
import LabCount from "./LabCount/LabCount";
import MenuButtons from "./MenuButtons/MenuButtons";

const JournalPanel = ({
    tableViewSettingsCookies,
    setTableViewSettingsCookies,
    displaySubgroupSetting,
    setDisplaySubgroupSetting,

    gradeBookStudents,
    setGradeBookStudents,
    gradeBookLessons,
    filterSubGroups,
    gradeBookFilterStudents,
    gradeBookFilterLessons,

    selectedSemesterIndex,
    selectedSubject,
    selectedTypeLesson,
    selectedGroupId,
    selectedGroupName,
    selectedTermHours,
    selectedSubgroup,
    selectedTeacher,
    isCalculatedLabCount,
    areSubGroupsValid,

    isOmissionsMode,
    setIsOmissionsMode,
    isGradeSettingMode,
    setIsGradeSettingMode,
    gradeSettingValue,
    setGradeSettingValue,
    isOpenLabDialog,
    setIsOpenLabDialog,
    selectedLabCount,
    setSelectedLabCount,
    labEditButtonState,
    setLabEditButtonState,
    loadGradeBookData,
}) => {

    const loadingGradeBook = useSelector(state => state.gradeBook.loadingGradeBookData);
    const gradeBookData = useSelector(state => state.gradeBook.gradeBookData);
    const gradeBookSubjects = useSelector(state => state.gradeBook.gradeBookSubjects);

    const [subgroupSetting, setSubgroupSetting] = useState(tableViewSettingsCookies.tableViewSettings?.subgroupSetting ?? true)
    const [teamSetting, setTeamSetting] = useState(tableViewSettingsCookies.tableViewSettings?.teamSetting ?? false)
    const [hourAmountSetting, setHourAmountSetting] = useState(tableViewSettingsCookies.tableViewSettings?.hourAmountSetting ?? false)
    const [markAmountSetting, setMarkAmountSetting] = useState(tableViewSettingsCookies.tableViewSettings?.markAmountSetting ?? false)
    const [gradePointAverageSetting, setGradePointAverageSetting] = useState(tableViewSettingsCookies.tableViewSettings?.gradePointAverageSetting ?? false)

    const dispatch = useDispatch();
    const [valueLabCount, setValueLabCount] = useState(6)

    const [checkNewLab, setNewCheckLab] = useState(true)
    const [isClickedGradeSettingButton, setIsClickedGradeSettingButton] = useState(false)

    const noActualGradebookData = !gradeBookData || loadingGradeBook;
    const isLab = selectedTypeLesson === 4;
    const isLabCount = isLab && selectedLabCount !== null

    const journalTableRef = useRef(null)

    const checkLab = () => {
        return !isLab || isLabCount
    }

    const handleResetGradeSettingMode = () => {
        if (journalTableRef.current) {
            journalTableRef.current.resetGradeSettingMode();
        }
    };

    const updateLabCount = (isCreate = false) => {
        setSelectedLabCount(valueLabCount)
        setValueLabCount(6)
        if (valueLabCount && selectedTermHours && selectedGroupId) {
            const paramsTmp = {
                termHoursId: selectedTermHours,
                studentGroupId: selectedGroupId,
                labCount: valueLabCount,
                isCreate: isCreate
            }
            const gradeBookSubjectsCopy = gradeBookSubjects.map(s => cloneDeep(s))
            gradeBookSubjectsCopy.find(sub => sub.name === selectedSubject).lessonTypes.find(type => type.lessonTypeId === selectedTypeLesson)
                .studentGroups.find(stg => stg.id === selectedGroupId).labCount = valueLabCount
            dispatch(editLabCount(paramsTmp, gradeBookSubjectsCopy))
        }
    }

    const isContainSubGroup = () => {
        return filterSubGroups.find(subgr => subgr.key === 1 || subgr.key === 2)
    }

    const setTableViewSettingsToCookies = (value, typeSetting) => {

        let disSubSetting = displaySubgroupSetting
        let subSetting = subgroupSetting
        let tmSetting = teamSetting
        let hamSetting = hourAmountSetting
        let mamSetting = markAmountSetting
        let gpaSetting = gradePointAverageSetting

        if (typeSetting === "displaySubgroupSetting") {
            setDisplaySubgroupSetting(value)
            disSubSetting = value
        } else if (typeSetting === "subgroupSetting") {
            setSubgroupSetting(value)
            subSetting = value
        } else if (typeSetting === "teamSetting") {
            setTeamSetting(value)
            tmSetting = value
        } else if (typeSetting === "hourAmountSetting") {
            setHourAmountSetting(value)
            hamSetting = value
        } else if (typeSetting === "markAmountSetting") {
            setMarkAmountSetting(value)
            mamSetting = value
        } else if (typeSetting === "gradePointAverageSetting") {
            setGradePointAverageSetting(value)
            gpaSetting = value
        }

        const tableViewSettings = {
            displaySubgroupSetting: disSubSetting,
            subgroupSetting: subSetting,
            teamSetting: tmSetting,
            hourAmountSetting: hamSetting,
            markAmountSetting: mamSetting,
            gradePointAverageSetting: gpaSetting,
        }
        setTableViewSettingsCookies("tableViewSettings", JSON.stringify(tableViewSettings), {
            path: "/",
            expires: getMonthExpirationDate()
        });
    }


    const setEmptySearchClipart = () => {
        return <div className={classes.noSearchContainer}>
            <img width={80} alt={"waiting"} src={FiltersWait} />
        </div>
    }

    const getGradeSettingButton = () => {
        const getGradeSettingMarkFormatter = (value) => {
            return isPercentage() && value ? `${value} %` : value
        }

        const maxGradeSettingMark = isPercentage() ? 100 : 10

        return <Popover open={isLabCount && isOpenLabDialog && !isOmissionsMode}
                        content={<div style={{marginBottom: '5px'}}>
                            <Checkbox checked={checkNewLab}
                                      onChange={(e) => {
                                          setNewCheckLab(e.target.checked)
                                      }}
                            />
                            <span style={{marginLeft: '5px'}}>за лр</span>
                        </div>}>
            <Space.Compact className={classes.gradeSettingInput}>
                <InputNumber value={gradeSettingValue}
                             formatter={(value) => isOmissionsMode && value ? `${value} ч.` : getGradeSettingMarkFormatter(value)}
                             className={isOmissionsMode && classes.omissionGradeSettingInput}
                             onChange={(value) => setGradeSettingValue(value)}
                             min={isOmissionsMode ? 1 : 0}
                             max={isOmissionsMode ? 4 : maxGradeSettingMark}
                             disabled={!isGradeSettingMode}
                />
                <Button type="primary" disabled={!isGradeSettingMode}
                        onClick={() => setIsClickedGradeSettingButton(true)}>Выставить</Button>
            </Space.Compact>
        </Popover>
    }

    const handleSegmentedChange = (value) => {
        handleResetGradeSettingMode()
        if (isGradeSettingMode) {
            const defaultMarkValue = isPercentage() ? null : 9
            setGradeSettingValue(value ? 2 : defaultMarkValue)
        }
        setIsOmissionsMode(value);
    }

    const getTableControls = () => {
        return <div className={classes.tableControlsDiv}>
            <Segmented
                disabled={noActualGradebookData || gradeBookFilterStudents.length === 0}
                className={classes.tabs}/*Копипаста мобильной версии*/
                value={isOmissionsMode}
                onChange={handleSegmentedChange}
                options={[
                    {
                        label: 'Оценки',
                        value: false
                    },
                    {
                        label: 'Пропуски',
                        value: true,
                        disabled: isPercentage()
                    },
                ]}
            />

            {getGradeSettingButton()}

            <MenuButtons
                gradeBookStudents={gradeBookStudents}
                gradeBookLessons={gradeBookLessons}
                filterSubGroups={filterSubGroups}
                gradeBookFilterStudents={gradeBookFilterStudents}

                selectedSemesterIndex={selectedSemesterIndex}
                selectedSubject={selectedSubject}
                selectedTypeLesson={selectedTypeLesson}
                selectedGroupId={selectedGroupId}
                selectedTermHours={selectedTermHours}
                selectedSubgroup={selectedSubgroup}
                selectedTeacher={selectedTeacher}

                isGradeSettingMode={isGradeSettingMode}
                selectedLabCount={selectedLabCount}
                loadGradeBookData={loadGradeBookData}
                journalTableRef={journalTableRef}
                areSubGroupsValid={areSubGroupsValid}
            />

            {
                isLabCount && !noActualGradebookData && 
                <LabCount
                    selectedLabCount={selectedLabCount}
                    labEditButtonState={labEditButtonState}
                    valueLabCount={valueLabCount}
                    setValueLabCount={setValueLabCount}
                    setLabEditButtonState={setLabEditButtonState}
                    updateLabCount={updateLabCount}
                />
            }

            <TableViewButton className={classes.rightTableControl}
                disabled={isPercentage() || noActualGradebookData || gradeBookFilterStudents.length === 0 || !areSubGroupsValid()}
                displaySubgroupSetting={displaySubgroupSetting}
                subgroupSetting={subgroupSetting}
                teamSetting={teamSetting}
                hourAmountSetting={hourAmountSetting}
                markAmountSetting={markAmountSetting}
                gradePointAverageSetting={gradePointAverageSetting}
                selectedTypeLesson={selectedTypeLesson}
                selectedSubject={selectedSubject}
                selectedSubgroup={selectedSubgroup}
                setTableViewSettings={setTableViewSettingsToCookies}
                isContainSubGroup={isContainSubGroup()}
            />
        </div>
    }

    const getContent = () => {
        return checkLab()
            ? getJournalContent()
            : getLabMessage(
                selectedSubject,
                gradeBookSubjects.find(sub => sub.name === selectedSubject)?.lessonTypes.find(type => type.lessonTypeId === selectedTypeLesson).studentGroups.find(gr => gr.id === selectedGroupId)?.name,
                valueLabCount,
                setValueLabCount,
                updateLabCount)
    }

    const getJournalContent = () => {
        return (!noActualGradebookData)
            ? <React.Fragment>
                <JournalTable
                    subgroupSetting={subgroupSetting}
                    teamSetting={teamSetting}
                    displaySubgroupSetting={displaySubgroupSetting}
                    hourAmountSetting={hourAmountSetting}
                    markAmountSetting={markAmountSetting}
                    gradePointAverageSetting={gradePointAverageSetting}
                    students={gradeBookFilterStudents}
                    selectedGroupName={selectedGroupName}
                    lessons={gradeBookFilterLessons}
                    reloadGradebookData={loadGradeBookData}
                    isOmissionsMode={isOmissionsMode}
                    setGradeBookStudents={setGradeBookStudents}
                    filterSubGroups={filterSubGroups}
                    selectedLabCount={selectedLabCount}
                    isLab={isLabCount}
                    isPercentage={isPercentage()}
                    selectedSubgroup={selectedSubgroup}
                    isGradeSettingMode={isGradeSettingMode}
                    setIsGradeSettingMode={setIsGradeSettingMode}
                    gradeSettingValue={gradeSettingValue}
                    setGradeSettingValue={setGradeSettingValue}
                    isClickedGradeSettingButton={isClickedGradeSettingButton}
                    setIsClickedGradeSettingButton={setIsClickedGradeSettingButton}
                    isOpenLabDialog={isOpenLabDialog}
                    setIsOpenLabDialog={setIsOpenLabDialog}
                    checkNewLab={checkNewLab}
                    setNewCheckLab={setNewCheckLab}
                    ref={journalTableRef}
                    areSubGroupsValid={areSubGroupsValid}
                />
            </React.Fragment>
            : <Spinner />
    }

    const isCourseProject = () => {
        return selectedTypeLesson === 5;
    }

    const isPercentage = () => {
        return isCourseProject();
    }


    return (
        <>
            {getTableControls()}
            {selectedSubject && selectedGroupId && selectedTypeLesson && (!isLab || (isLab && isCalculatedLabCount))
                ? getContent()
                : setEmptySearchClipart()}
        </>
    )
}

export default JournalPanel;