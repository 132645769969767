import React, {useEffect, useState} from "react";
import classes from './EmployeeLessonsMobile.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {combineLessons, currentLesson} from "../../../services/util";
import axios from '../../../services/axios';
import {
    getStudentGroupsOrEmployees,
    setFilterStudentGroupOrEmployee,
    setIsEmployee,
} from "../../../store/actions/gradeBook";
import {setMessage} from '../../../store/actions';
import Loader from "../../UI/Loader/Loader";
import {Select, Skeleton, Spin} from 'antd';
import LessonCard from "./LessonCard/LessonCard";
import {getFio} from "../../../services/commonService";
import DateButton from "../../UI/DateButton/DateButton";

const EmployeeLessonsMobile = () => {
    const [lessons, setLessons] = useState([])
    const date = useSelector(state => state.gradeBook.currentLessonsDate);
    const number = useSelector(state => state.gradeBook.lessonsNumber);
    const [isLoading, setIsLoading] = useState(false);
    const loadingStudentGroupOrEmployee = useSelector(state => state.gradeBook.loadingStudentGroupsOrEmployees);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);

    const isEmployee = useSelector(state => state.gradeBook.isEmployee)
    const [searchValue, setSearchValue] = useState([])
    const [selectedStudentGroupOrEmployee, selectStudentGroupOrEmployee] = useState(null)
    const studentGroupsOrEmployees = useSelector(state => state.gradeBook.studentGroupsOrEmployees);
    const user = useSelector(state => state.auth.user);
    const studentGroupOrEmployeeFromStore = useSelector(state => state.gradeBook.selectedStudentGroupOrEmployee)
    const [studentGroupOrEmployeeValue, setStudentGroupOrEmployeeValue] = useState([]);

    const [paramStudentGroupOrEmployee] = useState(params.get("studentGroupOrEmployee") ? params.get("studentGroupOrEmployee")?.split('.')[0] : null)

    useEffect(() => {
        let query = new URLSearchParams()
        if ((user.authorities.includes("ROLE_ADMIN") || user.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) && selectedStudentGroupOrEmployee !== null) {
            if (selectedStudentGroupOrEmployee) query += `&studentGroupOrEmployee=${isEmployee ? selectedStudentGroupOrEmployee.lastName + " " + selectedStudentGroupOrEmployee.firstName + " " + selectedStudentGroupOrEmployee.middleName + " " : selectedStudentGroupOrEmployee.name}`
        }
        
        navigate({
            pathname: '/gradebook',
            search: "?" + query.toString()
        })
    }, [selectedStudentGroupOrEmployee])

    useEffect(() => {
        if (searchValue.length !== 0) {
            if(/\d/.test(searchValue.toString())) {
                dispatch(getStudentGroupsOrEmployees(searchValue, false));
            }
            else {
                dispatch(getStudentGroupsOrEmployees(searchValue, true));
            }
        }
    }, [searchValue])

    const isGroupFormat = () => {
        return selectedStudentGroupOrEmployee && selectedStudentGroupOrEmployee.name !== undefined
            && /\d/.test(selectedStudentGroupOrEmployee.name.toString());
    }

    useEffect(() => {
        if (paramStudentGroupOrEmployee) {
            if(/\d/.test(paramStudentGroupOrEmployee.toString())) {
                axios.get(`/gradebook/student-groups?name=${paramStudentGroupOrEmployee}`)
                .then(response => {
                    setStudentGroupOrEmployeeValue(response.data);
                    dispatch(setIsEmployee(false))
                })
                .catch(_error => {
                    dispatch(setMessage({type: 'warning', text: "Ошибка при получении группы"}))
                })
            }
            else {
                axios.get(`/gradebook/teachers?fio=${paramStudentGroupOrEmployee}`)
                .then(response => {
                    setStudentGroupOrEmployeeValue(response.data);
                    dispatch(setIsEmployee(true))
                })
                .catch(_error => {
                    dispatch(setMessage({type: "warning", text: "Ошибка при получении преподавателя"}))
                })
            }
        }
    }, [paramStudentGroupOrEmployee])

    useEffect(() => {
        if (studentGroupOrEmployeeValue.length !== 0 && selectedStudentGroupOrEmployee === null) {
            selectStudentGroupOrEmployee(studentGroupOrEmployeeValue[0])
        }
    }, [studentGroupOrEmployeeValue])

    useEffect(() => {
        setIsLoading(true);
        let studentGroupOrEmployeeId = 0;
        if ((user.authorities.includes("ROLE_ADMIN") || user.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) && (selectedStudentGroupOrEmployee !== null || studentGroupOrEmployeeFromStore !== null)) {
            if (selectedStudentGroupOrEmployee !== null) {
                studentGroupOrEmployeeId = selectedStudentGroupOrEmployee.id
            }
            else if (studentGroupOrEmployeeFromStore !== null) {
                studentGroupOrEmployeeId = studentGroupOrEmployeeFromStore.id
            }
        }
        else {
            studentGroupOrEmployeeId = user.personId
        }
        axios.get('/gradebook/lessons-by-date',
            {
                params: {
                    lessonDate: date,
                    employeeId: isEmployee ? studentGroupOrEmployeeId : null,
                    studentGroupId: !isEmployee ? studentGroupOrEmployeeId : null,
                }
            })
            .then((response) => {
                setLessons(combineLessons(response.data));
            })
            .catch((_error) => {
                dispatch(setMessage({ type: 'warning', text: "Не удалось подключиться к серверу" }));
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [date, selectedStudentGroupOrEmployee])

    const isLabLesson = (lesson) => {
        return lesson.lessonTypeAbbrev === "ЛР";
    }

    const setStudentGroupOrEmployeeToFilter = (studentGroupOrEmployee) => {
        if(studentGroupOrEmployee === undefined) {
            selectStudentGroupOrEmployee(null);
        } else {
            let selectedStudentGroupOrEmployeeValue;
            if(isEmployee) selectedStudentGroupOrEmployeeValue = studentGroupsOrEmployees.find(stoe => (stoe.lastName + " " + stoe.firstName[0] + ". " + stoe.middleName[0] + ".") === studentGroupOrEmployee);
            else selectedStudentGroupOrEmployeeValue = studentGroupsOrEmployees.find(stoe => (stoe.name) === studentGroupOrEmployee);
            selectStudentGroupOrEmployee(selectedStudentGroupOrEmployeeValue)
            dispatch(setFilterStudentGroupOrEmployee(selectedStudentGroupOrEmployeeValue))
        }
    }

    const searchStudentGroupOrEmployee = (studentGroupOrEmployee) => {
        if(studentGroupOrEmployee === undefined) setSearchValue(null)
        else setSearchValue(studentGroupOrEmployee)
    }

    const getOptionsForStudentGroupsOrEmployees = () => {
        if(studentGroupsOrEmployees && studentGroupsOrEmployees.length !== 0) {
            if (!isEmployee) {
                return studentGroupsOrEmployees.map((studentGroupOrEmployee) => ({ key: studentGroupOrEmployee.id, value: studentGroupOrEmployee.name}))
            }
            else {
                return studentGroupsOrEmployees.map((studentGroupOrEmployee) => ({ key: studentGroupOrEmployee.id, value: studentGroupOrEmployee.lastName + " " + studentGroupOrEmployee.firstName[0] + ". " + studentGroupOrEmployee.middleName[0] + "." }))
            }
        }
        return null;
    }

    const contentNotFoundForStudentGroupsOrEmployees = () => {
        return loadingStudentGroupOrEmployee
            ? <Spin size="small"/>
            : <div style={{color: '#bfbfbf', padding: '6px'}}>Совпадения не найдены.</div>
    }

    const separatedFio = () => {
        let fioArray = user.fio.split(' ')
        return {
            key: user.id,
            value: getFio(fioArray[0], fioArray[1], fioArray[2])
        }
    }

    const getValueForSelector = () => {
        if(selectedStudentGroupOrEmployee) {
            dispatch(setFilterStudentGroupOrEmployee(selectedStudentGroupOrEmployee))
            return {
                key: selectedStudentGroupOrEmployee.id,
                value: selectedStudentGroupOrEmployee.name ? selectedStudentGroupOrEmployee.name
                    : selectedStudentGroupOrEmployee.lastName + " " + selectedStudentGroupOrEmployee.firstName[0] + ". " + selectedStudentGroupOrEmployee.middleName[0] + "."
            }
        } else if (studentGroupOrEmployeeFromStore !== null) {
            selectStudentGroupOrEmployee(studentGroupOrEmployeeFromStore)
            return {
                key: studentGroupOrEmployeeFromStore.id,
                value: isEmployee ? studentGroupOrEmployeeFromStore.lastName + " " + studentGroupOrEmployeeFromStore.firstName[0] + ". " + studentGroupOrEmployeeFromStore.middleName[0] + "."
                    : studentGroupOrEmployeeFromStore.name
            }
        } else {
            return separatedFio();
        }
    }

    return <div className={classes.container}>
        <Loader loading={isLoading && number == 0} />

        <div className={classes.header}>
            {(user?.authorities.includes("ROLE_ADMIN") || user?.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS"))
                ? <div className={classes.filterSelector}>
                    <div style={{ width: "100%", marginRight: '10px' }}>
                        <Select
                            style={{ width: '100%', fontSize: '16px' }}
                                placeholder="Поиск преподавателя или группы..."
                            showSearch={true}
                            options={getOptionsForStudentGroupsOrEmployees()}
                            notFoundContent={contentNotFoundForStudentGroupsOrEmployees()}
                            onSearch={searchStudentGroupOrEmployee}
                            onSelect={(e) => setStudentGroupOrEmployeeToFilter(e)}
                            value={getValueForSelector()}
                        />
                        <div className={classes.info}>
                            Введите ФИО преподавателя или № группы
                        </div>
                    </div>
                    <DateButton
                        date={date}
                        showStatusOfDate
                        onAction={() => navigate("/gradebook/calendar")}
                    />
                </div>
                : <DateButton
                    date={date}
                    showStatusOfDate
                    onAction={() => navigate("/gradebook/calendar")}
                    isWide
                />
            }
        </div>
       
        {(isLoading && number != 0) &&
            new Array(number).fill(0).map((_, index) =>
                <div key={index} className={classes.subjectDetails}>
                    <Skeleton
                    key={index}
                    active
                    title={false}
                    paragraph={{
                        rows: 3,
                        width: [150, 300, 150]
                    }}
                    className={classes.skeleton}/>
                </div>
            )
        }
        {lessons.length !== 0 &&
        <div style={{ margin: "0 5px" }}>
            {lessons.map((item) =>
            <div className={currentLesson(item[0]) ? classes.currentLesson : undefined} key={item.id}>
                <LessonCard item={item} key={item[0].id} isGroupFormat={isGroupFormat} isLabLesson={isLabLesson}/>
            </div>
            )}
       </div>
       }

       {(lessons.length === 0 && number == 0) &&
       <div className={classes.noLessons}>
            {"Нет занятий"}
       </div>
       }
    </div>
}

export default EmployeeLessonsMobile;