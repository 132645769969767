import classes from './LessonCard.module.scss';
import {MdOutlineKeyboardArrowRight, MdOutlinePlace} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import withRippleAnimation from '../../../../hoc/RippleAnimation/WithRippleAnimation';
import {formatFioWithInitials, getCombinedGroupNames} from "../../../../services/util";

const LessonCard = ({ item, onClick, key, isGroupFormat, isLabLesson }) => {
    const navigate = useNavigate();

    const setLessonType = (lesson) => {
        let itemClass = ''

        if (lesson.lessonTypeAbbrev === 'ЛК' || lesson.lessonTypeAbbrev === 'УЛк') itemClass = classes.markAsLecture
        if (lesson.lessonTypeAbbrev === 'ПЗ' || lesson.lessonTypeAbbrev === 'УПз') itemClass = classes.markAsSeminar
        if (lesson.lessonTypeAbbrev === 'ЛР' || lesson.lessonTypeAbbrev === 'УЛР') itemClass = classes.markAsLabWork
        if (lesson.lessonTypeAbbrev === 'Консультация') itemClass = classes.markAsConsultation
        if (lesson.lessonTypeAbbrev === 'Экзамен' || lesson.lessonTypeAbbrev === 'Кандидатский экзамен') itemClass = classes.markAsExam
        if (lesson.lessonTypeAbbrev === 'Зачет' || lesson.lessonTypeAbbrev === 'Кандидатский зачет') itemClass = classes.markAsOffset
        if (lesson.lessonTypeAbbrev === 'КПР(Р)') itemClass = classes.markAsCourseProject

        return itemClass
    }

    const getGroupNamesElements = (groups) => {
        const groupNames = [...new Set(getCombinedGroupNames(groups))];
        const groupElements = [];
        for (let i = 0; i < groupNames.length; i++) {
            groupElements.push(<span>
                { groupNames[i] }
                { i !== groupNames.length - 1 && ', ' }
            </span>)
        }
        return groupElements;
    }

    const getEmployeesElements = (employees) => {
        let employeesString = "";
        for (const element of employees) {
            employeesString += formatFioWithInitials(element) + ", "
        }
        return employeesString.substring(0, employeesString.length - 2);
    }

    const navigateToLesson = (item) => {
        if ((item[0].lessonTypeAbbrev === 'ЛК' || item[0].lessonTypeAbbrev === 'УЛк' || item[0].lessonTypeAbbrev === 'КПР(Р)') && item.length > 1) {
            navigate("/gradebook/byLesson/groups", { state: { lessons: [...item] } });
        } else {
            navigate("/gradebook/byLesson", { state: { selectedLesson: { ...item[0] } } });
        }
    }

    const getTeacherFormatLessonCard = () => {
        return <div
            className={classes.subjectDetails}
            onClick={(e) => onClick(e)}
            onAnimationEnd={() => navigateToLesson(item)}
        >
            <div className={setLessonType(item[0])}/>
            <div className={classes.lesson}>
                <div className={classes.lessonDetails}>
                    <div className={classes.subjectNameAndTime}>
                        <div className={classes.subjectName}>
                            {item[0].lessonName}
                            {' (' + item[0].lessonTypeAbbrev + ')'}
                        </div>
                        <div className={classes.lessonTime}>
                            {
                                item[0].startLessonTime
                                    ? <div>
                                        { ' ' + item[0].startLessonTime + '-' }
                                        { item[0].endLessonTime + ' ' }
                                    </div>
                                    : <br/>
                            }
                        </div>
                    </div>
                    {
                        item[0].auditory
                        && <div>
                            <MdOutlinePlace/>
                            <span className={classes.auditory}>{item[0].auditory}</span>
                        </div>
                    }
                </div>
                <div className={classes.additionalLessonDetails}>
                    <div className={classes.studentGroup}>
                        { ' гр. ' }
                        { getGroupNamesElements(item.map(l => ({...l.group}))) }
                    </div>
                    {
                        isLabLesson(item[0]) && item[0].subgroup !== 0
                            ? <span className={classes.subGroup}>{`${item[0].subgroup} п.`}</span>
                            : <div className={classes.subGroup}/>
                    }
                </div>
            </div>
            <div className={classes.arrowAndDividerContainer}>
                <div className={`${classes.verticalDivider}`}/>
                <MdOutlineKeyboardArrowRight size={30} className={classes.arrowIcon} />
            </div>
        </div>
    }

    const getGroupFormatLessonCard = () => {
        return <div
            className={classes.subjectDetails}
            onClick={(e) => onClick(e)}
            onAnimationEnd={() => navigateToLesson(item)}
        >
            <div className={setLessonType(item[0])}/>
            <div className={classes.lesson}>
                <div className={classes.lessonDetails}>
                    <div className={classes.subjectNameAndTime}>
                        <div className={classes.subjectName}>
                            { item[0].lessonName }
                            { ' (' + item[0].lessonTypeAbbrev + ')' }
                        </div>
                        <div className={classes.lessonTime}>
                            {
                                item[0].startLessonTime
                                    ? <div>
                                        { ' ' + item[0].startLessonTime + '-' }
                                        { item[0].endLessonTime + ' ' }
                                    </div>
                                    : <br/>
                            }
                        </div>
                    </div>
                    {
                        item[0].auditory && <div>
                            <MdOutlinePlace/>
                            <span className={classes.auditory}>
                                {item[0].auditory}
                            </span>
                        </div>
                    }
                </div>
                <div className={classes.additionalLessonDetails}>
                    <div
                        className={classes.teacher}
                        style={
                            isLabLesson(item[0]) && item[0].subgroup !== 0
                                ? {width: '90%'}
                                : {width: '100%'}
                        }
                    >
                        {
                            item[0].employees.length > 0
                                ? <div className={classes.teachers}>
                                    { getEmployeesElements(item[0].employees) }
                                </div>
                                : <div style={{height: '20px'}}/>
                        }
                    </div>
                    {
                        isLabLesson(item[0]) && item[0].subgroup !== 0
                            ? <div className={classes.subGroupContainer}>
                                <span className={classes.subGroup}>{`${item[0].subgroup} п.`}</span>
                            </div>
                            : <div className={classes.subGroup}/>
                    }
                </div>
            </div>
            <div className={classes.arrowAndDividerContainer}>
                <div className={`${classes.verticalDivider}`}/>
                <MdOutlineKeyboardArrowRight size={30} className={classes.arrowIcon} />
            </div>
        </div>
    }

    return (!isGroupFormat() ? getTeacherFormatLessonCard() : getGroupFormatLessonCard());
}

export default withRippleAnimation(LessonCard);
