import * as actionTypes from "./actionTypes";
import axios from "../../services/axios";
import { setMessage } from "./common";

export const LOGIN_EQUALS_PASSWORD = "LOGIN_EQUALS_PASSWORD";
export const BLOCKED = "BLOCKED";

export const loginProcessing = () => {
    return {
        type: actionTypes.LOGIN_PROCESSING,
    };
};

export const loginSuccess = (payload) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        user: payload,
    };
};

export const resetAuthMessage = () => {
    return {
        type: actionTypes.RESET_AUTH_MESSAGE
    }
}

export const loginFailed = (errorStatus) => {
    let errorMessage = ""
    switch (errorStatus) {
        case 401:
            errorMessage = "Неправильный пароль"
            break;
        case 404:
            errorMessage = "Учетная запись не найдена"
            break;
        default:
            errorMessage = "Неправильный логин или пароль"
    }

    return {
        type: actionTypes.LOGIN_FAILED,
        isBlocked: false,
        message: errorMessage,
    };
};

export const loginBlocked = () => {
    return {
        type: actionTypes.LOGIN_FAILED,
        isBlocked: true,
        message: "Превышено количество неудачных попыток входа.",
    };
};

const changePasswordFailed = () => {
    return {
        type: actionTypes.LOGIN_FAILED,
        isBlocked: false,   
        message: "Не удалось изменить пароль",
    };
};

export const resetLdapBanDate = (banDate) => {
    return {
        type: actionTypes.RESET_LDAP_BAN_DATE,
        banDate
    }
}

const setLoginThatEqualsPassword = (loginThatEqualsPassword) => {
    return {
        type: actionTypes.LOGIN_EQUALS_PASSWORD,
        loginThatEqualsPassword
    }
}

const getPhoto = (user, resolve, dispatch) => {
    axios.get('/auth/photo')
        .then(responsePhoto => {
            dispatch(loginSuccess({
                ...user.data,
                photoUrl: `data:image/jpeg;base64,${responsePhoto.data}`
            }))
        })
        .catch(() => dispatch(loginSuccess({ ...user.data, photoUrl: null })))
        .finally(() => resolve())
}

export const login = (username, password) => (dispatch) => {
    dispatch(loginProcessing())
    return new Promise(function (resolve, reject) {
        axios
            .post(`/auth/login`, {
                username: username,
                password: password,
            })
            .then((response) => {
                getPhoto(response, resolve, dispatch)
            })
            .catch((error) => {
                if (error?.response?.data?.code === LOGIN_EQUALS_PASSWORD) {
                    dispatch(setLoginThatEqualsPassword(username))
                    reject(error)
                } else if (error?.response?.data?.code === BLOCKED) {
                    dispatch(loginBlocked())
                    reject(error)
                } else {
                    dispatch(loginFailed(error?.response?.status));
                    reject(error);
                }
            })
    })
}

export const loginWithPasswordChange = (username, password, newPassword) => (dispatch) => {
    dispatch(loginProcessing())
    return new Promise(function (resolve, reject) {
        axios
            .post(`/auth/password/change`, {
                username, password, newPassword,
            })
            .then((response) => {
                getPhoto(response, resolve, dispatch)
            })
            .catch((error) => {
                if (error?.response?.data?.code === BLOCKED) {
                    dispatch(loginBlocked())
                    reject(error)
                } else {
                    dispatch(changePasswordFailed());
                    reject(error);
                }
            })
    })
}

export const logout = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        axios
            .get(`/auth/logout`)
            .then(() => {
                if (localStorage.getItem("user"))
                    localStorage.removeItem("user")
                if (localStorage.getItem("_expiredTime"))
                    localStorage.removeItem("_expiredTime")
                dispatch({
                    type: actionTypes.LOGOUT,
                });

                resolve();
            })
            .catch((error) => {
                dispatch(setMessage({ type: 'error', text: "Не удалось выйти из аккаунта." }))
                reject(error);
            });
    });

export const getCustomUser = (login) => (dispatch) => {
    return new Promise(function (resolve, reject) {
        axios
            .get(`/users/custom-user?login=${login}`)
            .then((response) => {
                getPhoto(response, resolve, dispatch)
            })
            .catch((error) => {
                dispatch(setMessage({ type: 'warning', text: "Не удалось перейти на аккаунт пользователя." }))
                reject(error);
            })
    })
}
