import {Button, Spin, Table} from 'antd';
import React, {useEffect, useMemo, useState} from "react";
import {generateYearsUsingLowerAndUpperBound, getLatestAcademicLoadYear} from "../../services/dateUtils";
import {useDispatch, useSelector} from "react-redux";
import {FaFileDownload} from "react-icons/fa";
import MobileTableItem from "../UI/MobileTableItem/MobileTableItem";
import Spinner from "../UI/Spinner/Spinner";
import StudyYearSelector from "./StudyYearSelector/StudyYearSelector";
import axios from "../../services/axios";
import classes from './Positions.module.scss';
import {getPositions} from "../../store/actions/positions";
import {setMessage} from "../../store/actions";
import useWindowDimensions from "../../hooks/windowDimensions";
import NotFound from "../UI/NotFound/NotFound";

const Positions = () => {
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();

    const positions = useSelector(state => state.positions.positionsData);
    const loadingPositions = useSelector(state => state.positions.loadingPositions);
    const civilContractMobileColumns = ["Отдел: ", "Сроки договора: ", "Часы: "];
    const positionMobileColumns = ["Отдел: ", "Должность: ", "Сроки контракта:", "Вид работы: ", "Ставка: "];
    const TEACHING_LOAD_HEADER = "Учебная нагрузка";
    const POSITIONS_HEADER = "Актуальные должности";
    const CIVIL_CONTRACTS_HEADER = "Гражданско-правовые договоры"

    const studyYearsForSelector = generateYearsUsingLowerAndUpperBound(2021, getLatestAcademicLoadYear());
    const [selectedStudyYear, setSelectedStudyYear] = useState(studyYearsForSelector[0]);
    const [isStudyLoadDownloading, setStudyLoadDownloading] = useState(false);

    const [civilContracts, filteredPositions] = useMemo(() => {
        return positions ? partition(positions, (pos) => pos.typeOfJob === 'Гражданско-правовой договор') : [[], []];
    }, [positions])

    function partition(userPositions, isCivilContract) {
        return userPositions.reduce(([pass, fail], position) => {
            return isCivilContract(position) ? [[...pass, position], fail] : [pass, [...fail, position]];
        }, [[], []]);
    }

    useEffect(() => {
        dispatch(getPositions());
    }, [dispatch])

    const positionsColumns
        = [
            {
                title: '№',
                dataIndex: 'id',
                width: '5%',
                align: 'center',
                render: (_, __, index) => ({children: <div>{index + 1}</div>})
            },
            {
                title: 'Отдел',
                dataIndex: 'department',
                width: '27%',
                align: 'center',
                render: (department) => ({children: <div style={{textAlign: 'left'}}>{department}</div>})
            },
            {
                title: 'Должность',
                dataIndex: 'position',
                width: '25%',
                align: 'center'
            },
            {
                title: 'Сроки контракта',
                dataIndex: 'contractDates',
                width: '18%',
                align: 'center'
            },
            {
                title: 'Вид работы',
                dataIndex: 'typeOfJob',
                width: '18%',
                align: 'center'
            },
            {
                title: 'Ставка',
                dataIndex: 'occupationRate',
                width: '7%',
                align: 'center'
            }
        ]

    const civilContractColumns
        = [
            {
                title: '№',
                dataIndex: 'id',
                width: '5%',
                align: 'center',
                render: (_, __, index) => ({children: <div>{index + 1}</div>})

            },
            {
                title: 'Отдел',
                dataIndex: 'department',
                width: '60%',
                align: 'center',
                render: (department) => ({children: <div style={{textAlign: 'left'}}>{department}</div>})
            },
            {
                title: 'Сроки договора',
                dataIndex: 'contractDates',
                width: '20%',
                align: 'center'
            },
            {
                title: 'Часы',
                dataIndex: 'occupationRate',
                width: '15%',
                align: 'center'
            }
        ]

    const setPositionsTable = (userPositions, columns, mobileColumns, isCivilContracts) => {
        return width <= 500 ? <React.Fragment>
            {userPositions.map((position, index) =>
                <MobileTableItem key={index}
                    isTitleWithData={true}
                    titles={mobileColumns}
                    data={!isCivilContracts ? [
                        position.department,
                        position.position,
                        position.contractDates,
                        position.typeOfJob,
                        position.occupationRate
                    ]
                        : [
                            position.department,
                            position.contractDates,
                            position.occupationRate
                        ]}
                />)}
        </React.Fragment>
            : <div className={classes.positionsContainer}>
                <div className={classes.tableContainer}>
                    <Table className={classes.table}
                        columns={columns}
                        stickyd
                        pagination={false}
                        dataSource={userPositions}/>
                </div>
            </div>
    }

    const getPositionsContent = () => {
        return !(JSON.parse(localStorage.getItem("user")).serviceUser)
            ? <div>
                <div style={{paddingBottom: '20px'}}>
                    <div className={classes.tableHeader}>{POSITIONS_HEADER}</div>
                    {(width > 500 || filteredPositions.length)
                        ? setPositionsTable(filteredPositions, positionsColumns, positionMobileColumns, false)
                        : <div className={classes.noPositions}>Нет актуальных должностей</div>}
                </div>
                <div style={{paddingBottom: '20px'}}>
                    <div className={classes.tableHeader}>{TEACHING_LOAD_HEADER}</div>
                    <div className={classes.teachingLoadContainer}>
                        <div className={classes.filterSelectName}>Учебный год</div>
                        <div className={classes.controls}>
                            <StudyYearSelector
                                className={classes.studyYearSelector}
                                studyYears={studyYearsForSelector}
                                value={selectedStudyYear}
                                onChange={setSelectedStudyYear}
                            />
                            <Button style={{margin: '0 10px 0 20px'}} className={classes.downloadButton}
                                icon={<FaFileDownload size={19}/>}
                                onClick={downloadTeachingLoad}>
                            Открыть
                            </Button>
                            <Spin spinning={isStudyLoadDownloading}/>
                        </div>
                    </div>
                </div>
                <div style={{paddingBottom: '20px'}}>
                    <div className={classes.tableHeader}>{CIVIL_CONTRACTS_HEADER}</div>
                    {(width > 500 || civilContracts.length)
                        ? setPositionsTable(civilContracts, civilContractColumns, civilContractMobileColumns, true)
                        : <div className={classes.noPositions}>Нет договоров</div>}
                </div>
            </div>
            : <NotFound />;
    }

    const downloadTeachingLoad = () => {
        setStudyLoadDownloading(true);
        axios
            .get(
                '/teaching-load/download',
                {
                    responseType: 'blob',
                    params: {
                        year: selectedStudyYear
                    }
                }
            )
            .then(response => {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileUrl = URL.createObjectURL(file);
                const newTab = window.open(fileUrl, '_blank');
                const tabCheckInterval = setInterval(() => {
                    if (newTab.closed) {
                        clearInterval(tabCheckInterval);
                        URL.revokeObjectURL(fileUrl);
                    }
                }, 30000);
            })
            .catch(error => {
                if (error?.response?.status === 404) {
                    dispatch(setMessage({type: 'warning', text: "Данные учебной нагрузки не найдены."}))
                } else {
                    dispatch(setMessage({type: 'error', text: "Не удалось получить данные."}))
                }
            })
            .finally(() => setStudyLoadDownloading(false))
    }

    return <div style={{width: width > 1000 && '65%'}}
        className={width > 500 ? classes.container : classes.mobileContainer}>
        {!loadingPositions && positions
            ? getPositionsContent() : <Spinner/>}
    </div>
}

export default Positions
