import {useEffect, useRef, useState} from "react";
import useLongPress from "../../../hooks/longPress";
import classes from "./IntegerInput.module.scss";

/*Изначально был добавлен, чтобы было возможно задать выравнивание в поле ввода без отображения кнопок инкремента*/
const IntegerInput = (props) => {

    const [value, setValue] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    useEffect(() => {
        if (props.needToFocus && inputRef) {
            inputRef.current.focus();
        }
    }, [props.needToFocus]);

    const isNumberInRange = (input) => {
        const maxIsSet = props.max === 0 || props.max;
        const minIsSet = props.min === 0 || props.min;
        return (props.isNullable && input === null) || (
            (!maxIsSet || (maxIsSet && input <= props.max))
            && (!minIsSet || (minIsSet && input >= props.min))
        );
    }

    const getValue = () => value !== null ? value : "";

    const longPressEvent = useLongPress(() => {
        if (props.onLongPress) {
            props.onLongPress();
        }
    });

    return <input
        disabled={props.isDisabled}
        className={`${!props.isJournal && classes.antInput} ${props.className} ${props.status === 'error' && classes.errorStatus}`}
        style={props.style}
        value={props.render ? props.render(value) : getValue()}
        onBlur={() => {
            if (props.onBlur) {
                const val = (value !== null && value?.length !== 0) ? parseInt(value) : null;
                props.onBlur(val);
            }
        }}
        inputMode="numeric"
        onChange={(e) => {
            const valueToHandle = props.mergeValues ? props.mergeValues(value, e.target.value) : e.target.value;
            if (valueToHandle === "-" && (props.min !== 0 && (!props.min || props.min < 0))) {
                setValue("-");
            } else {
                const intValue = parseInt(valueToHandle);
                const valueToBeCleared = props.valueToBeCleared ? props.valueToBeCleared(value, valueToHandle) : false;
                const val = (!valueToBeCleared && (intValue || intValue === 0)) ? intValue : null;
                if (isNumberInRange(val)) {
                    setValue(val);
                    if (props.onChange) {
                        props.onChange(val);
                    }
                }
            }
        }}
        onFocus={(e) => {
            if (props.onFocus) {
                props.onFocus(e);
            }
        }}
        onKeyDown={(e) => {
            if (props.onKeyDown) props.onKeyDown(e);
        }}
        onKeyUp={(e) => {
            if (props.onKeyUp) props.onKeyUp(e);
        }}
        {...longPressEvent}
        onClick={(e) => {
            if (props.onClick) props.onClick(e);
        }}
        ref={inputRef}
    />
}

export default IntegerInput;