import {MdBusinessCenter, MdOutlineAdminPanelSettings, MdOutlineAnnouncement, MdOutlineSettings, MdPersonOutline} from "react-icons/md";
import {BsJournalCheck} from "react-icons/bs";

export const createSidebarNavigationItems = (user) => {
    const getGradebookPage = () => {
        return user?.anyLessons || haveGradebookPermission()
            ? [{
                text: "Журнал успеваемости",
                path: `/gradebook`,
                icon: <BsJournalCheck size={25}/>
            }]
            : []
    }

    const haveGradebookPermission = () => {
        return user?.authorities?.includes("ROLE_ADMIN")
            || user?.authorities?.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS");
    }

    const getAnnouncementsPage = () => {
        return user?.anyLessons || user?.authorities.indexOf("ROLE_ADMIN") !== -1
            ? [{
                text: "Объявления",
                path: `/announcements`,
                icon: <MdOutlineAnnouncement size={25}/>
            }]
            : []
    }

    const getUsersPage = () => {
        return user?.authorities.indexOf("ROLE_ADMIN") !== -1
            ? [{
                text: "Пользователи",
                path: `/users`,
                icon: <MdOutlineAdminPanelSettings size={25}/>
            }]
            : []
    }

    const getPersonalPage = () => {
        return {
            text: "Личная страница",
            path: `/personal-page`,
            icon: <MdPersonOutline size={25}/>
        }
    }

    const getPositionsPage = () => {
        return {
            text: "Должности",
            path: `/positions`,
            icon: <MdBusinessCenter size={25}/>
        }
    }

    const permittedTabs = user?.serviceUser ? [] : [
        ...getGradebookPage(),
        ...getAnnouncementsPage(),
        ...getUsersPage(),
        getPersonalPage(),
        getPositionsPage()
    ];

    return [
        ...permittedTabs,
        {
            text: "Настройки",
            path: `/settings`,
            icon: <MdOutlineSettings size={25} />
        }
    ];
}
