import {Form, Input, Select} from "antd";
import classes from './EditableCell.module.scss';
import {getFormattedDateStringFromTimestamp} from "../../../../../services/dateUtils";
import React from "react";

const {Option} = Select
const {TextArea} = Input

const omissionOptions = [1, 2, 3, 4]
const markOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const EditableCell = ({form, editStudent, initialDate, dates, columnName, editing,
    dateOmission, editJournal, newRowKey, tableData, children, allLabNumbers, ...restProps}) => {

    const validateMarkAndOmission = () => {
        let formValues = form.getFieldsValue()
        if ((formValues.mark === undefined || formValues.mark === null) && !formValues.omission) return Promise.reject(new Error(''))
        else return Promise.resolve()
    }

    const hasDatePassed = () => {
        if (!form.getFieldsValue().omission || editJournal) {
            return true;
        }
        const currentDate = new Date()
        const newValueDateId = form.getFieldValue('newDate')
        const selectedDate = newRowKey
            ? new Date(dates.find(row => row.id === newValueDateId)?.date)
            : new Date(dateOmission);
        const hasOmissionForDate = newRowKey
            ? tableData.some(row => row.date.id === newValueDateId && row.omission !== null)
            : tableData.some(row => row.date.date === dateOmission && row.omission !== null);
        selectedDate?.setHours(23, 59, 59, 999)
        selectedDate?.setDate(selectedDate.getDate() + 1)
        return currentDate < selectedDate || (newRowKey && !hasOmissionForDate)
    };

    let cell
    if (editing) {
        if (columnName === 'newDate') {
            cell = <Form.Item name='newDate' initialValue={initialDate} style={{margin: 0}}>
                <Select className={classes.select}
                        popupClassName={classes.selectOption}
                        showSearch
                        filterOption={(input, option) => option.label.includes(input)}
                        options={dates.map(date => {
                            let sugGroup
                            if (date.subgroup === 1) sugGroup = ' (1 п.)'
                            else if (date.subgroup === 2) sugGroup = ' (2 п.)'
                            else sugGroup = ''
                            return {
                                value: date.id,
                                label: getFormattedDateStringFromTimestamp(date.date) + sugGroup
                            }
                        })}/>
            </Form.Item>
        } else if (columnName === 'mark') {
            cell = <Form.Item name='mark' style={{margin: 0}} rules={[{validator: () => validateMarkAndOmission()}]}>
                <Select className={classes.select}
                        popupClassName={classes.selectOption}
                        allowClear
                        options={markOptions.map(item => ({value: item, label: item}))}/>
            </Form.Item>
        } else if (columnName === 'markLabNumber') {
            cell = <Form.Item name='markLabNumber' initialValue={editStudent.freeLabNumbers.length !== 0 ? editStudent.freeLabNumbers[0] : null} style={{margin: 0}}>
                <Select className={classes.select}
                        popupClassName={classes.selectOption}
                        allowClear>
                    {allLabNumbers.map(item => (
                        <Option value={item}
                                label={item}
                                disabled={!editStudent.freeLabNumbers.includes(item)}
                                children={item}/>
                    ))}
                </Select>
            </Form.Item>
        } else if (columnName === 'markNote') {
            cell = <Form.Item name='markNote' style={{margin: 0}}>
                <TextArea className={classes.textArea}
                          autoSize={{minRows: 1, maxRows: 5}}
                          allowClear/>
            </Form.Item>
        } else if (columnName === 'omission') {
            cell = <Form.Item name='omission' style={{margin: 0}} rules={[{validator: () => validateMarkAndOmission()}]}>
                <Select className={classes.select}
                        popupClassName={classes.selectOption}
                        allowClear
                        disabled={!hasDatePassed()}
                        options={omissionOptions.map(item => ({value: item, label: item}))}/>
            </Form.Item>
        } else if (columnName === 'omissionNote') {
            cell = <Form.Item name='omissionNote' style={{margin: 0}}>
                <TextArea className={classes.textArea}
                          autoSize={{minRows: 1, maxRows: 5}}
                          allowClear/>
            </Form.Item>
        }
    } else cell = children

    return (
        <td {...restProps}>
            {cell}
        </td>
    )
}

export default EditableCell