import useWindowDimensions from "../../hooks/windowDimensions";
import EmployeeLessonsMobile from "./EmployeeLessonsMobile/EmployeeLessonsMobile";
import ProfileJournalDesktop from "./ProfileJournalDesktop/ProfileJournalDesktop";
import NotFound from "../UI/NotFound/NotFound";
import React from "react";

const Gradebook = () => {

    const { width } = useWindowDimensions();

    return (
        !(JSON.parse(localStorage.getItem("user")).serviceUser) ? <div>
            {
                width <= 500
                    ? <EmployeeLessonsMobile />
                    : <ProfileJournalDesktop />
            }
        </div>
            : <NotFound />);
}

export default Gradebook;