import {Button, Checkbox, Collapse, DatePicker, Input, Modal, Select} from 'antd';
import {Picker} from 'antd-mobile';
import React, {useEffect, useState} from 'react';
import classes from './AnnouncementDialog.module.scss';
import announcementsClasses from "../../Announcements.module.scss";
import axios from '../../../../services/axios';
import {useDispatch, useSelector} from 'react-redux';
import {getFilteredAuditory} from '../../../../store/actions/auditory';
import {getFilteredGroups} from '../../../../store/actions/group';
import dayjs from 'dayjs';
import {setMessage} from "../../../../store/actions";
import Loader from "../../../UI/Loader/Loader";
import {
    currentWeek,
    dateFormat,
    getFilteredRepeatDates,
    getRepeatDates,
    getRepeatFromForMessage,
    getRepeatMessage,
    getRepeatToForMessage,
    getRepeatWeeks,
    WEEKS_AMOUNT
} from "../../../../services/Announcements/announcementsService";
import DateInput from "../../../UI/Input/DateInput";
import {
    getCurrentWeekFilters,
    getFullFormattedDateStringFromTimestamp,
    getUtcDate
} from "../../../../services/dateUtils";
import locale from 'dayjs/locale/ru';

const {Panel} = Collapse;
const {TextArea} = Input;

function generateArrayWithTimeOptions(n) {
    return [...Array.from(Array(n).keys()).map(i => {
        return {
            label: (i < 10 ? '0' : '') + String(i),
            value: (i < 10 ? '0' : '') + String(i)
        }
    })];
}

const hoursAndMinutesPickerData = [
    generateArrayWithTimeOptions(24),
    generateArrayWithTimeOptions(60)
]

const AnnouncementDialog = (props) => {

    const currentTime = new Date();

    const dispatch = useDispatch();

    const auditoryOptions = useSelector(state => state.auditory.filteredAuditory);
    const groupOptions = useSelector(state => state.group.filteredGroups);

    const [date, setDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);
    const [startTimePickerVisible, setStartTimePickerVisible] = useState(false);
    const [endTimePickerVisible, setEndTimePickerVisible] = useState(false);
    const [startTimePickerValue, setStartTimePickerValue] = useState([String(currentTime.getHours()), String(currentTime.getMinutes())]);
    const [startTime, setStartTime] = useState(null);
    const [endTimePickerValue, setEndTimePickerValue] = useState([String(currentTime.getHours()), String(currentTime.getMinutes())]);
    const [endTime, setEndTime] = useState( null);
    const [description, setDescription] = useState( null);
    const [selectedAuditory, setSelectedAuditory] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isRepeat, setIsRepeat] = useState(false);
    const [weekFilters, setWeekFilters] = useState(getCurrentWeekFilters())
    const [repeatFrom, setRepeatFrom] = useState(dayjs())
    const [repeatTo, setRepeatTo] = useState(dayjs())
    const [isEditRepeatAnnouncement, setEditIsRepeatAnnouncement] = useState(false)

    const [dateStatus, setDateStatus] = useState('');
    const [descriptionStatus, setDescriptionStatus] = useState('');
    const [startTimeStatus, setStartTimeStatus] = useState('');
    const [endTimeStatus, setEndTimeStatus] = useState('');
    const [repeatToStatus, setRepeatToStatus] = useState('')
    const [repeatWeekStatus, setRepeatWeekStatus] = useState('')

    useEffect(() => {
        setDate(props.edit && props.date ? dayjs(props.date) : null);
        setStartTime(props.startTime);
        setEndTime(props.endTime);
        setDescription(props.name);
        setSelectedAuditory(props.auditory ? mapAuditoryToOption(props.auditory) : null);
        setSelectedGroups(props.groups ? props.groups.map(g => mapGroupToOption(g)) : []);

        let isEditRepeatAnnouncementTmp = props.edit && props.repeatGroup && props.announcements.filter(an => an.repeatGroup === props.repeatGroup).length > 1
        setEditIsRepeatAnnouncement(isEditRepeatAnnouncementTmp)
        setIsRepeat(isEditRepeatAnnouncementTmp)
        if (isEditRepeatAnnouncementTmp) {
            let repeatDates = getFilteredRepeatDates(props.repeatGroup, props.announcements)
            setRepeatFrom(getFullFormattedDateStringFromTimestamp(repeatDates[0]))
            setRepeatTo(getFullFormattedDateStringFromTimestamp(repeatDates[repeatDates.length - 1]))
            setWeekFilters(getRepeatWeeks(repeatDates))
        }
    }, []);

    useEffect(() => {
        if (date && repeatTo && dayjs(date, dateFormat).isAfter(dayjs(repeatTo, dateFormat))) setRepeatTo(null)
    }, [date])

    useEffect(() => {
        if (repeatFrom && repeatTo && dayjs(repeatFrom, dateFormat).isAfter(dayjs(repeatTo, dateFormat))) setRepeatTo(null)
    }, [repeatFrom])

    const mapGroupToOption = (group) => {
        return {
            value: group.id,
            label: group.name
        };
    }

    const mapAuditoryToOption = (auditory) => {
        return {
            value: auditory.id,
            label: `${auditory.name}-${auditory.buildingNumber} (${auditory.auditoryType})`
        };
    }

    const contentNotFound = () => {
        return <div style={{color: '#bfbfbf', padding: '6px'}}>Совпадения не найдены.</div>
    }

    const getTitle = () => {
        return props.edit ? 'Изменить объявление' : 'Добавить объявление';
    }

    const handleAuditorySearch = (value) => {
        if (value.trim().length !== 0) {
            dispatch(getFilteredAuditory(value));
        }
    }

    const handleGroupSearch = (value) => {
        if (value.trim().length !== 0) {
            dispatch(getFilteredGroups(value));
        }
    }

    const clearRepeatData = () => {
        setIsRepeat(false)
        setWeekFilters(getCurrentWeekFilters())
        setRepeatTo(null)
        setDateStatus('')
        setDescriptionStatus('')
        setStartTimeStatus('')
        setEndTimeStatus('')
        setRepeatToStatus('')
        setRepeatWeekStatus('')
    }

    const addNewAnnouncement = () => {
        validateInputs();
        let dates = []
        if (isRepeat && date && repeatTo) dates = getRepeatDates(date, repeatTo, weekFilters)

        if (date && startTime && endTime && description && startTime < endTime && (!isRepeat || (isRepeat && repeatTo && weekFilters.find(week => week === true)))) {
            setIsLoading(true);
            axios.post('/announcements', {
                announcementDto: {
                    date: date.toISOString(),
                    startTime: startTime,
                    endTime: endTime,
                    name: description,
                    auditory: selectedAuditory ? {id: selectedAuditory.value} : null,
                    groups: selectedGroups.map(o => ({id: o.value})),
                }, repeatDates: isRepeat ? dates : [dayjs(date, dateFormat)]
            })
                .then(_response => {
                    if (props.onOk) {
                        props.onOk();
                        clearRepeatData()
                    }
                })
                .catch(_error => {
                    dispatch(setMessage({ type: 'warning', text: 'Не удалось добавить новое объявление.'}));
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const editAnnouncement = (id) => {
        validateInputs();
        let dates = []
        if (isRepeat && repeatFrom && repeatTo) dates = getRepeatDates(repeatFrom, repeatTo, weekFilters)

        if (date && startTime && endTime && description && startTime < endTime && (!isRepeat || (isRepeat && repeatTo && weekFilters.find(week => week === true)))) {
            setIsLoading(true);
            console.log(selectedGroups);
            axios.put(`/announcements/${id}`, {
                announcementDto: {
                    date: date.toISOString(),
                    startTime: startTime,
                    endTime: endTime,
                    name: description,
                    auditory: selectedAuditory ? {id: selectedAuditory.value} : null,
                    groups: selectedGroups.map(o => ({id: o.value})),
                    repeatGroup: props.repeatGroup
                }, repeatDates: isRepeat ? dates : [dayjs(date, dateFormat)]
            })
                .then(_response => {
                    if (props.onOk) {
                        props.onOk();
                        clearRepeatData()
                    }
                })
                .catch(_error => {
                    dispatch(setMessage({ type: 'warning', text: 'Не удалось изменить объявление.'}));
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    const validateRepeatData = () => {
        if (isRepeat && !repeatTo) setRepeatToStatus("error")
        else setRepeatToStatus("")
        if (isRepeat && weekFilters.find(week => week === true) === undefined) setRepeatWeekStatus("error")
        else setRepeatWeekStatus("")
    }

    const validateInputs = () => {
        if (!date) {
            setDateStatus("error");
        } else {
            setDateStatus("");
        }
        if (!startTime) {
            setStartTimeStatus("error");
        } else {
            setStartTimeStatus("");
        }
        if (!endTime || (startTime && endTime && (startTime >= endTime))) {
            setEndTimeStatus("error");
        } else {
            setEndTimeStatus("");
        }
        if (!description) {
            setDescriptionStatus("error");
        } else {
            setDescriptionStatus("");
        }
        validateRepeatData()
    }

    const handleChangeRepeat = () => {
        if (isRepeat) {
            selectAllWeeksHandler(false)
            setRepeatTo(null)
        }
        setIsRepeat(!isRepeat)
    }

    const selectWeekHandler = (value, index) => {
        const tmp = [...weekFilters]
        tmp[index] = value.target.checked
        setWeekFilters(tmp)
    }

    const selectAllWeeksHandler = (value) => {
        const tmp = Array(WEEKS_AMOUNT).fill(value)
        tmp[currentWeek] = true
        setWeekFilters(tmp)
    }

    const setWeekFiltersArray = () => {
        const weekFiltersArray = []
        for (let i = 0; i < WEEKS_AMOUNT; i++) {
            weekFiltersArray.push(
                <Checkbox
                    key={`week${i}`}
                    checked={weekFilters[i]}
                    className={`${announcementsClasses.weekCheckbox} ${repeatWeekStatus ? classes.errorCheckbox : null}`}
                    onChange={(e) => selectWeekHandler(e, i)}>
                    {`${i + 1}`}
                </Checkbox>
            )
        }
        weekFiltersArray.push(<Checkbox
            key="weekAll"
            status={repeatWeekStatus}
            checked={weekFilters.every(weekState => weekState === true)}
            className={`${announcementsClasses.weekCheckbox} ${repeatWeekStatus ? classes.errorCheckbox : null}`}
            onChange={(e) => selectAllWeeksHandler(e.target.checked)}>
            Все
        </Checkbox>)
        return weekFiltersArray
    }

    const handleDateChange = (value) => {
        setDate(getUtcDate(value));
        if (!props.edit) {
            setRepeatFrom(value);
        }
    }

    return (
        <div>
            <Loader loading={isLoading} />
            <Modal
                centered
                title={getTitle()}
                open={true}
                className={classes.modalContainer}
                onCancel={() => {
                    if (props.onCancel) {
                        props.onCancel();
                        clearRepeatData()
                    }
                }}
                footer={(<div className={classes.footer}>
                    {props.edit && props.getDeleteButton(isEditRepeatAnnouncement)}
                    <div>
                        <Button
                            onClick={(_e) => {
                                if (props.onCancel) {
                                    props.onCancel();
                                    clearRepeatData()
                                }
                            }}>
                            Отмена
                        </Button>
                        <Button type='primary'
                                onClick={(_e) => {
                                    if (!props.edit) {
                                        addNewAnnouncement();
                                    } else {
                                        editAnnouncement(props.id);
                                    }
                                }}>
                            Ок
                        </Button>

                    </div>
                </div>)}>

                <DatePicker locale={locale}
                            className={classes.datePicker}
                            allowClear={false}
                            inputReadOnly
                            placeholder='Выберите дату'
                            value={date}
                            onChange={handleDateChange}
                            status={dateStatus}
                            disabled={isEditRepeatAnnouncement}
                            disabledDate={(dateTmp) => dateTmp.isBefore(dayjs().startOf('day'))}
                />

                <div className={classes.timePickerContainer}>
                    <Input className={classes.timePicker}
                           readOnly
                           status={startTimeStatus}
                           placeholder='Начало'
                           onFocus={() => setStartTimePickerVisible(true)}
                           value={startTime} />

                    <Input className={classes.timePicker}
                           readOnly
                           status={endTimeStatus}
                           placeholder='Конец'
                           onFocus={() => setEndTimePickerVisible(true)}
                           value={endTime} />
                </div>

                {(!props.edit || (props.edit && isEditRepeatAnnouncement)) &&
                    <Collapse activeKey={isRepeat ? 1 : null}
                              collapsible="icon"
                              style={{marginBottom: '16px'}}
                              ghost
                              expandIcon={() =>
                                  <Checkbox checked={isRepeat}
                                            disabled={isEditRepeatAnnouncement}
                                            onChange={handleChangeRepeat}
                                            className={announcementsClasses.repeatCheckbox}>
                                      Повторять {isRepeat ? getRepeatMessage(getRepeatFromForMessage(repeatFrom, date), weekFilters, getRepeatToForMessage(repeatTo)) : ''}
                                  </Checkbox>}>
                        <Panel className={announcementsClasses.repeatPanel} key="1">
                            <div className={announcementsClasses.repeatContainer}>
                                <div style={{marginBottom: '5px'}}><div>Недели:</div>{setWeekFiltersArray()}</div>
                                {isEditRepeatAnnouncement &&
                                    <div className={announcementsClasses.repeatDateContainer} style={{marginRight: '10px'}}>
                                        <div style={{display: 'inline-block', width: '20px', marginRight: '5px'}}>C</div>
                                        <DateInput placeholder={'C'}
                                                   format={'YYYY-MM-DD'}
                                                   inputReadOnly={true}
                                                   value={repeatFrom ? dayjs(repeatFrom, dateFormat) : repeatFrom}
                                                   onChange={setRepeatFrom}
                                                   style={{marginBottom: '10px'}}
                                                   disabledDate={(dateTmp) => dateTmp.isBefore(dayjs().startOf('day'))}
                                                   className={announcementsClasses.repeatSelectDate}/>
                                    </div>}
                                <div className={announcementsClasses.repeatDateContainer}>
                                    <div style={{display: 'inline-block', width: '20px', marginRight: '5px'}}>До</div>
                                    <DateInput placeholder={'До'}
                                               format={'YYYY-MM-DD'}
                                               status={repeatToStatus}
                                               inputReadOnly={true}
                                               value={repeatTo ? dayjs(repeatTo, dateFormat) : repeatTo}
                                               onChange={setRepeatTo}
                                               disabledDate={(dateTmp) => dateTmp.isBefore(dayjs().startOf('day')) || dateTmp.isBefore(dayjs(isEditRepeatAnnouncement ? repeatFrom : date, dateFormat))}
                                               className={announcementsClasses.repeatSelectDate}/>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>}

                <TextArea className={classes.descriptionInput}
                    rows={3}
                    status={descriptionStatus}
                    placeholder='Описание'
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }} />

                <Select
                    className={classes.auditorySelector}
                    showSearch
                    labelInValue
                    allowClear
                    filterOption={false}
                    placeholder='Аудитория'
                    defaultValue={selectedAuditory}
                    notFoundContent={contentNotFound()}
                    size='large'
                    onSearch={(v) => handleAuditorySearch(v)}
                    options={auditoryOptions?.map(item => ({
                        value: item.id,
                        label: `${item.name}-${item.buildingNumber} (${item.auditoryType})`
                    }))}
                    value={selectedAuditory}
                    onChange={(v) => {
                        setSelectedAuditory(v);
                    }} />

                <Select
                    mode='multiple'
                    className={classes.groupSelector}
                    allowClear
                    labelInValue
                    filterOption={false}
                    placeholder='Группы'
                    notFoundContent={contentNotFound()}
                    size='large'
                    onChange={(v) => {
                        setSelectedGroups(v);
                    }}
                    value={selectedGroups}
                    onSearch={(v) => handleGroupSearch(v)}
                    options={groupOptions?.map(item => ({
                        value: item.id,
                        label: item.name
                    }))} />
            </Modal>

            <Picker
                title='Время начала'
                columns={hoursAndMinutesPickerData}
                visible={startTimePickerVisible}
                onClose={() => {
                    setStartTimePickerVisible(false);
                }}
                onConfirm={() => {
                    setStartTime(startTimePickerValue[0] + ':' + startTimePickerValue[1]);
                }}
                value={startTimePickerValue}
                onSelect={(v) => {
                    setStartTimePickerValue(v);
                }}
                cancelText='Отмена'
                confirmText='Выбрать'
                renderLabel={(item) => {
                    return <span style={{ fontSize: '24px'}}>{item.label}</span>
                }}
            />

            <Picker
                title='Время окончания'
                columns={hoursAndMinutesPickerData}
                visible={endTimePickerVisible}
                onConfirm={() => {
                    setEndTime(endTimePickerValue[0] + ':' + endTimePickerValue[1]);
                }}
                onClose={() => {
                    setEndTimePickerVisible(false);
                }}
                value={endTimePickerValue}
                onSelect={(v) => {
                    setEndTimePickerValue(v);
                }}
                cancelText='Отмена'
                confirmText='Выбрать'
                renderLabel={(item) => {
                    return <span style={{ fontSize: '24px' }}>{item.label}</span>
                }}
            />
        </div>
    )
}

export default AnnouncementDialog;