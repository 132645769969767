import {Button, Checkbox, Collapse, Input, Modal, Select, Spin, TimePicker} from "antd";
import classes from "./EditAnnouncementDialog.module.scss";
import announcementsClasses from "../../Announcements.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DateInput from "../../../UI/Input/DateInput";
import React, {useEffect, useState} from "react";
import {
    getCurrentWeekFilters,
    getFormattedTimeFromTimestamp,
    getFullFormattedDateStringFromTimestamp,
    getUtcDate
} from "../../../../services/dateUtils";
import dayjs from 'dayjs';
import {useDispatch, useSelector} from "react-redux";
import {getFilteredAuditory} from "../../../../store/actions/auditory";
import {getFilteredGroups} from "../../../../store/actions/group";
import {addNewAnnouncement, editAnnouncement} from "../../../../store/actions/announcements";
import {
    currentWeek,
    dateFormat,
    getFilteredRepeatDates,
    getRepeatDates,
    getRepeatFromForMessage,
    getRepeatMessage,
    getRepeatToForMessage,
    getRepeatWeeks,
    WEEKS_AMOUNT
} from "../../../../services/Announcements/announcementsService";

const {TextArea} = Input;
const {RangePicker} = TimePicker;
const {Panel} = Collapse;
const timeFormat = 'HH:mm';

const EditAnnouncementDialog = (props) => {
    const dispatch = useDispatch();
    const updatingAnnouncement = useSelector(state => state.announcements.updatingAnnouncement)

    const loadingAuditoryOptions = useSelector(state => state.auditory.loadingFilteredAuditory)
    const auditoryOptions = useSelector(state => state.auditory.filteredAuditory)
    const loadingGroupOptions = useSelector(state => state.group.loadingFilteredAuditory)
    const groupOptions = useSelector(state => state.group.filteredGroups)

    const [announcementDate, setAnnouncementDate] = useState(!props.isAdd && getFullFormattedDateStringFromTimestamp(props.editAnnouncement.date));
    const [startTime, setStartTime] = useState(props?.editAnnouncement?.startTime && dayjs(props.editAnnouncement.startTime, timeFormat))
    const [endTime, setEndTime] = useState(props?.editAnnouncement?.endTime && dayjs(props.editAnnouncement.endTime, timeFormat))
    const [event, setEvent] = useState(props?.editAnnouncement?.name && props.editAnnouncement.name)
    const [auditory, setAuditory] = useState(props?.editAnnouncement?.auditory && {value: props.editAnnouncement.auditory.id, label: `${props.editAnnouncement.auditory.name}-${props.editAnnouncement.auditory.buildingNumber} (${props.editAnnouncement.auditory.auditoryType})`})
    const [groups, setGroups] = useState(props?.editAnnouncement?.groups ? props.editAnnouncement.groups.map(item => ({value: item.id, label: item.name})) : [])
    const [isRepeat, setIsRepeat] = useState(props.updateAllRepeatAnnouncement)
    const [updateAllRepeatAnnouncement] = useState(props.updateAllRepeatAnnouncement)
    const [weekFilters, setWeekFilters] = useState(getCurrentWeekFilters())
    const [repeatFrom, setRepeatFrom] = useState(dayjs())
    const [repeatTo, setRepeatTo] = useState(dayjs())

    useEffect(() => {
        if (updateAllRepeatAnnouncement) {
            let repeatDates = getFilteredRepeatDates(props.editAnnouncement.repeatGroup, props.announcements)
            setRepeatFrom(getFullFormattedDateStringFromTimestamp(repeatDates[0]))
            setRepeatTo(getFullFormattedDateStringFromTimestamp(repeatDates[repeatDates.length - 1]))
            setWeekFilters(getRepeatWeeks(repeatDates))
        }
    }, [])

    useEffect(() => {
        if (props.isAdd && announcementDate && repeatTo && dayjs(announcementDate, dateFormat).isAfter(dayjs(repeatTo, dateFormat))) setRepeatTo(null)
    }, [announcementDate])

    useEffect(() => {
        if (repeatFrom && repeatTo && dayjs(repeatFrom, dateFormat).isAfter(dayjs(repeatTo, dateFormat))) setRepeatTo(null)
    }, [repeatFrom])

    const getTitle = () => {
        return props.isAdd ? 'Добавить объявление' : 'Изменить объявление';
    }

    const createAnnouncementDto = () => {
        return {
            date: getUtcDate(announcementDate),
            startTime: getFormattedTimeFromTimestamp(startTime),
            endTime: getFormattedTimeFromTimestamp(endTime),
            name: event,
            auditory: auditory ? {id: auditory.value} : null,
            groups: groups.map(item => ({id: item.value})),
            repeatGroup: !props.isAdd ? props.editAnnouncement.repeatGroup : null
        }
    }

    const onSaveNewAnnouncement = () => {
        let dates = []
        if (isRepeat && announcementDate && repeatTo) dates = getRepeatDates(announcementDate, repeatTo, weekFilters)
        dispatch(addNewAnnouncement({announcementDto: createAnnouncementDto(), repeatDates: isRepeat ? dates : [getUtcDate(announcementDate)]}, props.onSuccess))
    }

    const onEditAnnouncement = () => {
        let dates = []
        if (isRepeat && repeatFrom && repeatTo) dates = getRepeatDates(repeatFrom, repeatTo, weekFilters)
        dispatch(editAnnouncement(props.editAnnouncement.id, {announcementDto: createAnnouncementDto(), repeatDates: isRepeat ? dates : [getUtcDate(announcementDate)]}, props.onSuccess))
    }

    const handleTime = (times) => {
        if (times === null) {
            setStartTime(null)
            setEndTime(null)
        } else {
            setStartTime(times[0])
            setEndTime(times[1])
        }
    }

    const contentNotFound = () => {
        return loadingAuditoryOptions || loadingGroupOptions ? <Spin size="small"/> : <div>Совпадения не найдены.</div>
    }

    const handleSearchAuditory = (value) => {
        if (value.length >= 2 && value.trim().length !== 0)
            dispatch(getFilteredAuditory(value))
    }

    const handleChangeAuditory = (value) => {
        if (value) setAuditory(value)
        else setAuditory(null)
    }

    const getAuditoryOptions = () => {
        return auditoryOptions && auditoryOptions.map(item => ({value: item.id, label: `${item.name}-${item.buildingNumber} (${item.auditoryType})`}))
    }

    const handleSearchGroup = (value) => {
        if (value.length >= 2 && value.trim().length !== 0)
            dispatch(getFilteredGroups(value))
    }

    const handleChangeGroup = (selectedGroups) => {
        if (selectedGroups) setGroups(selectedGroups)
        else setGroups(null)
    }

    const handleChangeRepeat = () => {
        if (isRepeat) {
            selectAllWeeksHandler(false)
            setRepeatTo(null)
        }
        setIsRepeat(!isRepeat)
    }

    const selectWeekHandler = (value, index) => {
        const tmp = [...weekFilters]
        tmp[index] = value.target.checked
        setWeekFilters(tmp)
    }

    const selectAllWeeksHandler = (value) => {
        const tmp = Array(WEEKS_AMOUNT).fill(value)
        tmp[currentWeek] = true
        setWeekFilters(tmp)
    }

    const setWeekFiltersArray = () => {
        const weekFiltersArray = []
        for (let i = 0; i < WEEKS_AMOUNT; i++) {
            weekFiltersArray.push(
                <Checkbox
                    key={`week${i}`}
                    checked={weekFilters[i]}
                    className={announcementsClasses.weekCheckbox}
                    onChange={(e) => selectWeekHandler(e, i)}>
                    {`${i + 1} ${(i === currentWeek) ? '(текущая)' : ''}`}
                </Checkbox>
            )
        }
        weekFiltersArray.push(<Checkbox
            key="weekAll"
            checked={weekFilters.every(weekState => weekState === true)}
            className={announcementsClasses.weekCheckbox}
            onChange={(e) => selectAllWeeksHandler(e.target.checked)}>
            Все
        </Checkbox>)
        return weekFiltersArray
    }

    const handleAnnouncementDateChange = (value) => {
        setAnnouncementDate(value);
        if (props.isAdd) {
            setRepeatFrom(value);
        }
    }

    const getRepeatCollapse = () => {
        return <Collapse activeKey={isRepeat && 1}
                  collapsible="icon"
                  ghost
                  expandIcon={() =>
                      <Checkbox checked={isRepeat}
                                disabled={updateAllRepeatAnnouncement}
                                onChange={handleChangeRepeat}
                                className={announcementsClasses.repeatCheckbox}>
                          Повторять {isRepeat ? getRepeatMessage(getRepeatFromForMessage(repeatFrom, announcementDate), weekFilters, getRepeatToForMessage(repeatTo)) : ''}
                      </Checkbox>}>
            <Panel className={announcementsClasses.repeatPanel} key="1">
                <div className={announcementsClasses.repeatContainer}>
                    <div style={{marginBottom: '5px'}}>
                        <div className={announcementsClasses.repeatField}>Недели:</div>
                        {setWeekFiltersArray()}
                    </div>
                    {updateAllRepeatAnnouncement &&
                    <div style={{display: 'inline-block', marginRight: '10px'}}>
                        <div className={announcementsClasses.repeatField}>Повторять c</div>
                        <DateInput value={repeatFrom ? dayjs(repeatFrom, dateFormat) : repeatFrom}
                                   onChange={setRepeatFrom}
                                   style={{width: '113px'}}
                                   disabledDate={(dateTmp) => dateTmp.isBefore(dayjs().startOf('day'))}
                                   className={announcementsClasses.repeatSelectDate}/>
                    </div>}
                    <div style={{display: 'inline-block'}}>
                        <div className={announcementsClasses.repeatField}>Повторять до</div>
                        <DateInput value={repeatTo ? dayjs(repeatTo, dateFormat) : repeatTo}
                                   onChange={setRepeatTo}
                                   style={{width: updateAllRepeatAnnouncement ? '113px' : '150px'}}
                                   disabledDate={(dateTmp) => dateTmp.isBefore(dayjs().startOf('day')) || dateTmp.isBefore(dayjs(updateAllRepeatAnnouncement ? repeatFrom : announcementDate, dateFormat))}
                                   className={announcementsClasses.repeatSelectDate}/>
                    </div>
                </div>
            </Panel>
        </Collapse>
    }

    const getOkButtonDisabledProp = () => {
        return !announcementDate || !startTime || !endTime
            || !event || (event && event.length === 0)
            || (isRepeat && (!repeatTo || weekFilters.find(week => week === true) === undefined))
    }

    return <Modal title={getTitle()}
                  open={true}
                  onOk={props.isAdd ? onSaveNewAnnouncement : onEditAnnouncement}
                  okButtonProps={{disabled: getOkButtonDisabledProp()}}
                  cancelButtonProps={{disabled: updatingAnnouncement}}
                  onCancel={props.onClose}
                  cancelText="Отменить"
                  okText="Сохранить"
                  confirmLoading={updatingAnnouncement}
                  width={510}
                  centered={true}>
        <div style={{display: 'flex'}}>
            <div style={{marginRight: '20px'}} className={classes.selectContainer}>
                <div className={classes.selectName}>Дата{!announcementDate && <span style={{color: 'red'}}> *</span>}</div>
                <DateInput defaultValue={announcementDate}
                           onChange={handleAnnouncementDateChange}
                           className={classes.selectButton}
                           disabledDate={(dateTmp) => dateTmp.isBefore(dayjs().startOf('day'))}
                           disabled={updateAllRepeatAnnouncement}
                />
            </div>
            <div style={{flex: 1}} className={classes.selectContainer}>
                <div className={classes.selectName}>Время{(!startTime || !endTime) && <span style={{color: 'red'}}> *</span>}</div>
                <RangePicker defaultValue={[startTime, endTime]}
                             format={timeFormat}
                             className={classes.selectButton}
                             allowClear={false}
                             onChange={handleTime}
                             needConfirm={false}
                             allowEmpty={[true, true]}
                />
            </div>
        </div>

        {(props.isAdd || (!props.isAdd && updateAllRepeatAnnouncement))
            && getRepeatCollapse()}

        <div className={classes.selectContainer}>
            <div className={classes.selectName}>Событие{!event && <span style={{color: 'red'}}> *</span>}</div>
            <TextArea className={classes.selectButton}
                      value={event}
                      onChange={(e) => setEvent(e.target.value)}
                      placeholder="Событие"
                      allowClear
                      autoSize={{minRows: 3, maxRows: 5}}
            />
        </div>
        <div className={classes.selectContainer}>
            <div className={classes.selectName}>Аудитория</div>
            <Select className={classes.selectButton}
                    showSearch={true}
                    allowClear
                    labelInValue
                    defaultActiveFirstOption={true}
                    placeholder='Аудитория'
                    defaultValue={auditory}
                    filterOption={false}
                    notFoundContent={contentNotFound()}
                    onSearch={handleSearchAuditory}
                    onChange={handleChangeAuditory}
                    options={getAuditoryOptions()}
            />
        </div>
        <div className={classes.selectContainer}>
            <div className={classes.selectName}>Группы</div>
            <Select className={classes.selectButton}
                    mode="multiple"
                    showSearch={true}
                    allowClear
                    labelInValue
                    defaultActiveFirstOption={true}
                    placeholder='Группы'
                    defaultValue={groups}
                    filterOption={false}
                    notFoundContent={contentNotFound()}
                    onSearch={handleSearchGroup}
                    onChange={handleChangeGroup}
                    options={groupOptions && groupOptions.map(item => ({value: item.id, label: item.name}))}
            />
        </div>
        {!props.isAdd && props.getDeleteButton(props.editAnnouncement.id, props.editAnnouncement.repeatGroup, props.announcements,
            <Button danger
                    className={`${classes.deleteButton}`}
                    disabled={updatingAnnouncement}>
                <FontAwesomeIcon icon={faTrash}/> Удалить
            </Button>,
            <Button danger
                    onClick={() => props.onDeleteAnnouncement(props.editAnnouncement.id)}
                    className={`${classes.deleteButton}`}
                    disabled={updatingAnnouncement}>
                <FontAwesomeIcon icon={faTrash}/> Удалить
            </Button>, false, updatingAnnouncement)}
    </Modal>
}

export default EditAnnouncementDialog