import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import Timer from "../services/Timer"
import {SESSION_LIFETIME} from "../services/constants"

import { logout, setMessage } from "../store/actions"
import { LOGIN_EQUALS_PASSWORD } from "../store/actions/authentication"
import { isLessThatTwoWeeksUntilLdapBan } from "../services/dateUtils";

export const useAuthenticate = (loginAction) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  return () => {
    dispatch(loginAction())
      .then(() => {
        const timer = new Timer({
          timeout: SESSION_LIFETIME,
          onTimeout: () => {
            dispatch(setMessage({ type: 'error', text: "Ваша сессия устарела." }))
            dispatch(logout()).then(() => navigate("/", { replace: true }))
            timer.cleanUp()
          },
        });
        if (JSON.parse(localStorage.getItem("user")).serviceUser
            || isLessThatTwoWeeksUntilLdapBan()) {
            navigate('/settings');
        } else if (location.search) {
          const returnUrl = location.search.replace("returnUrl=", '').slice(1)
          navigate(returnUrl, { replace: true })
        } else {
          navigate('/gradebook', { replace: true })
        }
      })
      .catch((error) => {
        if (error?.response?.data?.code === LOGIN_EQUALS_PASSWORD) {
          navigate('/login/change-password')
        }
        if (localStorage.getItem('user')) localStorage.removeItem('user')
        if (localStorage.getItem('_expiredTime')) localStorage.removeItem('_expiredTime')
      })
  };
}