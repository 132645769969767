import { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Form, Input } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import { IoKeySharp } from "react-icons/io5";
import { useNavigate } from "react-router";

import classes from './GetRestorableAccount.module.scss'
import axios from '../../../../services/axios'
import { setMessage } from "../../../../store/actions";
import { recoveryMethods } from "../recoveryMethods";
import { BAN_DURATION } from "../../../../services/constants";

import PasswordRecoveryWrapper from "../PasswordRecoveryWrapper";
import SubmitButton from "../../../UI/SubmitButton/SubmitButton";

const GetRestorableAccount = () => {
    const [username, setUsername] = useState('')
    const [loading, setLoading] = useState(false)
    const [ifError, setIfError] = useState(false)
    const [ifUnmount, setIfUnmount] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSubmit = () => {
        axios.get(`/password-reset/phone?login=${username}`)
            .then((response) => submitLoginSuccess(response))
            .catch((error) => submitLoginError(error))
    }

    const submitLoginSuccess = (response) => {
        const contactExtraInformation = recoveryMethods.find(method => method.typeId === 4)
        delete contactExtraInformation.icon

        const payload = {
            contact: {
                ...response.data,
                ...contactExtraInformation
            },
            login: username
        }

        setLoading(false)
        setIfUnmount(true)

        setTimeout(() => {
            navigate("/password-recovery/mask", { state: payload })
        }, 400);

    }

    const submitLoginError = (error) => {
        setLoading(false)
        switch (error.response.status) {
            case 403:
                dispatch(setMessage({ type: 'warning', text: "Для восстановления пароля данного пользователя обратитесь к администратору." }))
                break;
            case 404:
                setIfError(true)
                break;
            case 429:
                setIfUnmount(true)
                setTimeout(() => {
                    navigate("/password-recovery/error",
                        {
                            state:
                                { time: error.response.data.banExpirationTime ?? new Date(new Date().getTime() + BAN_DURATION) }
                        })
                }, 400);
                break;
            default:
                dispatch(setMessage({ type: 'warning', text: "Не удалось подключиться к серверу." }))
        }
    }

    return <PasswordRecoveryWrapper icon={<IoKeySharp size={35} />} title="Восстановление пароля">
        <div className={!ifUnmount ? classes.container : classes[`container--unmounted`]}>
            <Form
                name="password_recovery"
                scrollToFirstError={true}
                onFinish={handleSubmit}>
                <Form.Item>
                    <div className={classes.extra}>Для восстановления пароля введите имя пользователя, используемое для входа в личный кабинет</div>
                </Form.Item>

                <Form.Item
                    name="login"
                    hasFeedback={true}
                    rules={[
                        { required: true, message: 'Данное поле является обязательным!' },
                        {
                            pattern: /^[a-zA-Z0-9_\-.]+$/g,
                            message: 'Некорректный формат логина!'
                        }
                    ]}>
                    <Input
                        prefix={<AiOutlineUser />}
                        placeholder="Логин"
                        value={username}
                        onChange={(e) => setUsername(e.target.value.trim())} />
                </Form.Item>

                <Form.Item>
                    <SubmitButton
                        type="submit"
                        text="Продолжить"
                        block
                        loading={loading}
                    />
                </Form.Item>

                {(ifError) && <Form.Item>
                    <Alert
                        message="Ошибка!"
                        description="Не удалось найти учетную запись."
                        type="error"
                        showIcon
                        className={classes.errorContainer}
                    />
                </Form.Item>}
            </Form>
        </div>
    </PasswordRecoveryWrapper>
}

export default GetRestorableAccount;