import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isLoginProcessing: false,
    isBlocked: false,
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
    message: null,
    loginThatEqualsPassword: null,
};

const loginProcessing = (state) => {
    return {
        ...state,
        isLoginProcessing: true
    }
}

const loginSuccess = (state, action) => {
    localStorage.setItem("user", JSON.stringify({ ...action.user }));
    return {
        ...state,
        isLoginProcessing: false,
        isBlocked: false,
        user: { ...action.user },
        message: null,
        loginThatEqualsPassword: null,
    }
}

const loginFailed = (state, action) => {
    return {
        ...state,
        message: action.message,
        isBlocked: action.isBlocked,
        isLoginProcessing: false,
        user: null,
    }
}

const setLoginThatEqualsPassword = (state, action) => {
    return {
        ...state,
        loginThatEqualsPassword: action.loginThatEqualsPassword,
    }
}

const logout = (state) => {
    return {
        ...state,
        isLoginProcessing: false,
        isBlocked: false,
        user: null,
        message: null,
    }
}

const resetAuthMessage = (state) => {
    return {
        ...state,
        message: null
    }
}

const resetLdapBanDate = (state, action) => {
    const updatedUser = { ...state.user, ldapBanDate: action.banDate };
    localStorage.setItem('user', JSON.stringify(updatedUser));

    return {
        ...state,
        user: updatedUser
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_PROCESSING: return loginProcessing(state);
        case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
        case actionTypes.LOGIN_FAILED: return loginFailed(state, action);
        case actionTypes.LOGOUT: return logout(state);
        case actionTypes.RESET_AUTH_MESSAGE: return resetAuthMessage(state);
        case actionTypes.LOGIN_EQUALS_PASSWORD: return setLoginThatEqualsPassword(state, action);
        case actionTypes.RESET_LDAP_BAN_DATE: return resetLdapBanDate(state, action);
        default: return state;
    }
};
export default reducer;