import classes from './JournalTable.module.scss';
import {MdStarRate} from "react-icons/md";

const JournalLegend = () => {

    return <div className={classes.tableLegend}>
        <div>
            <span style={{color:'green'}}>Зеленым </span>
            выделены пропуски по уважительной причине (могут выставляться автоматически при одобрении деканатом заявлений на ОРВИ). 
        </div>
        <div> 
            <span style={{color:'red'}}>Красным </span>выделены пропуски по неуважительной причине.
        </div>
        <div>
            <MdStarRate size={20} style={{color: '#1677ff', verticalAlign: 'top'}}/>
            выделен староста группы.
        </div>
        <br/>
        <div>
            В соответствии с п. 3.3 протокола заседания ректората №27 от 25.11.2024:
            "Установить возможность ввода данных в электронный журнал пропусков учебных занятий (модуль в составе ИИС "БГУИР: Университет") старостам учебных групп, ППС и работникам деканатов факультетов.
            Установить возможность корректировки введённых данных работникам деканатов факультетов (бессрочно) и ППС (до 23:59 дня, следующего за днем проведения занятия)".
            Соответствующая функциональность реализована с 31.12.2024.
        </div>
        <br/>
    </div>  
}

export default JournalLegend;
