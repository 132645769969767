import React, {useState} from 'react';
import { Tabs } from 'antd';
import {IoCamera, IoLockClosedOutline} from "react-icons/io5";
import { IoIosPhonePortrait } from "react-icons/io";

import classes from './ProfileSettings.module.scss'
import useWindowDimensions from '../../hooks/windowDimensions';

import ChangePassword from './ChangePassword/ChangePassword';
import ManageContacts from './ManageContacts/ManageContacts';
import ChangePhoto from "./ChangePhoto/ChangePhoto";
import { useSelector } from "react-redux";
import { Alert, Card } from "react-bootstrap";
import { getFullFormattedDateStringFromTimestamp, isLessThatTwoWeeksUntilLdapBan } from "../../services/dateUtils";

const ProfileSettings = () => {
    const { width } = useWindowDimensions()
    const user = useSelector(state => state.auth.user);
    const twoWeeksInMilliseconds = 1000 * 60 * 60 * 24 * 7 * 2;

    const [confirmedPhone, setConfirmedPhone] = useState(null);

    const [activeTab, setActiveTab] = useState(isLessThatTwoWeeksUntilLdapBan() ? 0 : 1)

    const permittedTabs = !JSON.parse(localStorage.getItem("user")).serviceUser ? [
        {
            key: 1,
            label: 'Контакты',
            icon: <IoIosPhonePortrait />,
            contentTitle: "Редактирование контактных данных",
            content: <ManageContacts
                confirmedPhone={confirmedPhone}
            />
        },
        {
            key: 2,
            label: 'Фото',
            icon: <IoCamera />,
            contentTitle: "Загрузить фото",
            content: <ChangePhoto />
        }
    ]
        : []

    const passwordTab = {
        key: 0,
        label: 'Пароль',
        icon: <IoLockClosedOutline />,
        contentTitle: "Изменение пароля",
        content: <ChangePassword
            setConfirmedPhone={phone => setConfirmedPhone(phone)}
        />
    }

    const tabs = [passwordTab, ...permittedTabs]

    const notifyAboutUpcomingLdapBan = () => {
        return user?.ldapBanDate != null && (new Date(user?.ldapBanDate) - new Date()) < twoWeeksInMilliseconds;
    }

    const renderLdapBanIsIncomingText = () => {
        return (
            <Card.Body className={classes.alertCard}>
                <Alert className={classes.alert} variant="danger">
                    <Alert.Heading>
                        <div className={classes.alertHeader}>
                            Срок действия Вашего пароля истекает {getFullFormattedDateStringFromTimestamp(user?.ldapBanDate)}.
                        </div>
                        В соответствии с приказом ректора № 207 от 28.05.2020 "Об утверждении политики безопасности компьютерной сети"  Вам необходимо изменить пароль до указанной даты. В противном случае Вам будет ограничен доступ к ресурсам и сервисам БГУИР(интернет, почта, ИИС "БГУИР:Университет" и проч.).
                    </Alert.Heading>
                </Alert>
            </Card.Body>

        )
    }

    return <>
        { notifyAboutUpcomingLdapBan() ? renderLdapBanIsIncomingText() : null }
        <div className={classes.container}>
            <Tabs
                centered={width <= 500}
                className={classes.tabList}
                defaultActiveKey={activeTab}
                onChange={(key) => setActiveTab(parseInt(key, 10))}
                items={tabs.map(tab => ({
                    key: tab.key,
                    label: <span>{tab.icon}{tab.label}</span>,
                    children: tab.content
                }))
                }
            />
        </div>
    </>
}

export default ProfileSettings;
