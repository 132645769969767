import IntegerInput from "./IntegerInput";

const OmissionHoursInput = (props) => {
    const renderHours = value => value ? `${value} ч` : '';

    return <IntegerInput
        isDisabled={props.isDisabled}
        min={1}
        max={4}
        value={props.value}
        isNullable={true}
        className={props.className}
        isJournal={true}
        style={props.style}
        render={renderHours}
        valueToBeCleared={(oldValue, newValue) => {
            const renderedValue = renderHours(oldValue);
            if (renderedValue.length > newValue.length) {
                const deletedCharacter = [...renderedValue].find((char, i) => (i >= newValue.length || char !== newValue[i]));
                return !(/^\d$/.test(deletedCharacter));
            } else return false;
        }}
        onBlur={(value) => props.onBlur(value)}
        onFocus={() => {
            if (props.onFocus) props.onFocus()
        }}
        onKeyDown={(e) => {
            if (props.onKeyDown) props.onKeyDown(e);
        }}
        onChange={(val) => {
            if (typeof props.onChange === 'function') {
                props.onChange(val);
            }
        }}
    />
}

export default OmissionHoursInput;