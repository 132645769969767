import {useDispatch, useSelector} from "react-redux";
import {
    getGradeBookData,
    getGradeBookSubjects,
    getStudentGroupsOrEmployees,
    getStudentPhotos,
} from "../../../store/actions/gradeBook";
import React, {useEffect, useState, useMemo} from "react";
import {Select, Spin} from "antd";
import classes from './ProfileJournalDesktop.module.scss';
import {useCookies} from "react-cookie";
import {
    generateSemesters,
    getCurrentEducationalYear,
    getCurrentHalfOfYear,
} from "../../../services/dateUtils";
import {useNavigate} from "react-router-dom";
import {getFio} from "../../../services/commonService";
import axios from "../../../services/axios";
import {setMessage} from "../../../store/actions";
import JournalPanel from "./JournalPanel/JournalPanel";
import GroupedSelector from "./SelectorOptions/GroupedSelector";
import { PiChalkboardTeacher } from "react-icons/pi";
import { getSubGroupMessage } from "../../../services/Gradebook/gradeBookService";

const ProfileJournalDesktop = () => {

    const subgroups = [
        {
            key: 0,
            name: 'Вся группа'
        },
        {
            key: 1,
            name: 'Первая подгруппа'
        },
        {
            key: 2,
            name: 'Вторая подгруппа'
        }
    ]

    const getSemesters = () => generateSemesters(3);

    const getDefaultSemesterIndex = () => {
        return getSemesters().findIndex(sem => sem.year === getCurrentEducationalYear() && sem.halfOfYear.key === getCurrentHalfOfYear().key)
    }

    const getInitialSelectedSemesterIndexValue = () => {
        const year = params.get("year");
        const halfOfYear = params.get("halfOfYear");
        if (year && halfOfYear) {
            const index = getSemesters().findIndex(sem => sem.year === parseInt(year) && sem.halfOfYear.key === halfOfYear);
            return index === -1 ? getDefaultSemesterIndex() : index;
        } else {
            return getDefaultSemesterIndex();
        }
    }

    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search);

    const loadingGradeBook = useSelector(state => state.gradeBook.loadingGradeBookData);
    const gradeBookData = useSelector(state => state.gradeBook.gradeBookData);
    const studentPhotosLoaded = useSelector(state => state.gradeBook.studentPhotosLoaded);
    const [isNewGradeBookData, setIsNewGradeBookData] = useState(false)
    const [isNewGradeBookSubjects, setIsNewGradeBookSubjects] = useState(false)
    const loadingGradeBookSubjects = useSelector(state => state.gradeBook.loadingGradeBookSubjects);
    const loadingGradeBookTeachers = useSelector(state => state.gradeBook.loadingStudentGroupsOrEmployees);
    const gradeBookSubjects = useSelector(state => state.gradeBook.gradeBookSubjects);
    const gradeBookTeachers = useSelector(state => state.gradeBook.studentGroupsOrEmployees);
    const user = useSelector(state => state.auth.user);


    const [tableViewSettingsCookies, setTableViewSettingsCookies] = useCookies(["tableViewSettings"])
    const [displaySubgroupSetting, setDisplaySubgroupSetting] = useState(tableViewSettingsCookies.tableViewSettings && tableViewSettingsCookies.tableViewSettings.displaySubgroupSetting !== null ? tableViewSettingsCookies.tableViewSettings.displaySubgroupSetting : false)

    const [gradeBookStudents, setGradeBookStudents] = useState([])
    const [gradeBookLessons, setGradeBookLessons] = useState([])
    const [filterSubGroups, setFilterSubGroups] = useState([])
    const [gradeBookFilterStudents, setGradeBookFilterStudents] = useState([])
    const [gradeBookFilterLessons, setGradeBookFilterLessons] = useState([])
    const [selectedSemesterIndex, setSelectedSemesterIndex] = useState(getInitialSelectedSemesterIndexValue())
    const [selectedGroup, setSelectedGroup] = useState(params.get("group") ?? null)
    const selectedSubject = selectedGroup ? selectedGroup.split('_')[0] : null
    const selectedTypeLesson = selectedGroup ? parseInt(selectedGroup.split('_')[1]) : null
    const selectedGroupId = selectedGroup ? parseInt(selectedGroup.split('_')[2]) : null
    const [selectedGroupName, setSelectedGroupName] = useState(null)
    const [selectedTermHours, setSelectedTermHours] = useState(params.get("termHours") ? parseInt(params.get("termHours")) : null)
    const [selectedSubgroup, setSelectedSubgroup] = useState(params.get("subgroup") ? parseInt(params.get("subgroup")) : null)
    const [searchValueOfTeacher, setSearchValueOfTeacher] = useState([])
    const [selectedTeacher, selectTeacher] = useState(null)

    const [isOmissionsMode, setIsOmissionsMode] = useState(false);
    const [isGradeSettingMode, setIsGradeSettingMode] = useState(false)
    const [gradeSettingValue, setGradeSettingValue] = useState(null)
    const [isOpenLabDialog, setIsOpenLabDialog] = useState(false)

    const dispatch = useDispatch();
    const [gradebookTeacher, setGradebookTeacher] = useState([]);

    const [paramTeacher] = useState(params.get("teacher") ? params.get("teacher")?.split('.')[0] : null)
    const [isCalculatedLabCount, setIsCalculatedLabCount] = useState(false)
    const [selectedLabCount, setSelectedLabCount] = useState(null)
    const [labEditButtonState, setLabEditButtonState] = useState(true)

    useEffect(() => {
        if (gradeBookData && isNewGradeBookData) {
            setGradeBookStudents(gradeBookData.students)
            setGradeBookLessons(gradeBookData.lessons)
            let subgroup = 0
            if (gradeBookData.lessons.length !== 0) {
                const filterSubGroupTmp = subgroups.filter(subgr => gradeBookData.lessons.find(les => les.subgroup === subgr.key))
                subgroup = params.get("subgroup") && filterSubGroupTmp.find(subgr => subgr.key === parseInt(params.get("subgroup"))) ? parseInt(params.get("subgroup")) : filterSubGroupTmp[0].key
                setGradeBookFilterStudents(subgroup === 0 ? gradeBookData.students : gradeBookData.students.filter(stud => stud.subgroup === subgroup))
                setGradeBookFilterLessons(gradeBookData.lessons.filter(les => les.subgroup === subgroup))
                setFilterSubGroups(filterSubGroupTmp)
            }
            setSelectedSubgroup(subgroup)

            if (displaySubgroupSetting) {
                setGradeBookFilterStudents(gradeBookData.students)
                setGradeBookFilterLessons(gradeBookData.lessons)
            } else {
                setGradeBookFilterStudents(subgroup ? gradeBookData.students.filter(stud => stud.subgroup === subgroup) : gradeBookData.students)
                setGradeBookFilterLessons(gradeBookData.lessons.filter(les => les.subgroup === subgroup))
            }
        }
    }, [gradeBookData])

    useEffect(() => {
        if (selectedSubgroup !== null && gradeBookStudents && gradeBookLessons) {
            if (displaySubgroupSetting && selectedSubgroup === 0) {
                setGradeBookFilterStudents(gradeBookStudents)
                setGradeBookFilterLessons(gradeBookLessons)
            } else {
                setGradeBookFilterStudents(selectedSubgroup === 0 ? gradeBookStudents : gradeBookStudents.filter(stud => stud.subgroup === selectedSubgroup))
                setGradeBookFilterLessons(gradeBookLessons.filter(les => les.subgroup === selectedSubgroup
                    || (les.subgroup === 0 && displaySubgroupSetting)))
            }
        }
    }, [selectedSubgroup, displaySubgroupSetting, gradeBookStudents])

    useEffect(() => {
        const semester = getSelectedSemester();
        let query = new URLSearchParams({year: semester.year, halfOfYear: semester.halfOfYear.key})
        if ((user.authorities.includes("ROLE_ADMIN") || user.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) && selectedTeacher !== null) {
            if (selectedTeacher) query += `&teacher=${selectedTeacher.lastName + " " + selectedTeacher.firstName + " " + selectedTeacher.middleName + " "}`
        }
        if (selectedGroup) query += `&group=${selectedGroup}`
        if (selectedTermHours) query += `&termHours=${selectedTermHours}`
        if (selectedSubgroup !== null) query += `&subgroup=${selectedSubgroup}`
        navigate({
            pathname: '/gradebook',
            search: "?" + query.toString()
        })
    }, [selectedTeacher, selectedSemesterIndex, selectedGroup, selectedSubgroup])

    useEffect(() => {
        if (gradeBookSubjects.length !== 0 && selectedSubject && selectedTypeLesson && selectedGroup && selectedTypeLesson === 4 && !selectedLabCount) {
            setIsCalculatedLabCount(true)
            setSelectedLabCount(gradeBookSubjects.find(sub => sub.name === selectedSubject).lessonTypes.find(type => type.lessonTypeId === selectedTypeLesson).studentGroups.find(stg => stg.id === selectedGroupId).labCount)
        } else {
            if ((user.authorities.includes("ROLE_ADMIN") || user.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) && selectedTeacher !== null) {
                if (gradeBookSubjects.length !== 0 && selectedTeacher && gradebookTeacher !== 0 && selectedSubject && selectedGroup && selectedTypeLesson && selectedTermHours && checkLab()) {
                    loadGradeBookData();
                }
            } else {
                if (gradeBookSubjects.length !== 0 && selectedSubject && selectedGroup && selectedTypeLesson && selectedTermHours && checkLab()) {
                    loadGradeBookData();
                }
            }
        }
    }, [selectedTeacher, gradeBookSubjects, selectedSubject, selectedGroup, selectedTypeLesson, isCalculatedLabCount])

    useEffect(() => {
        if (searchValueOfTeacher.length !== 0) {
            dispatch(getStudentGroupsOrEmployees(searchValueOfTeacher, true));
        }
    }, [searchValueOfTeacher])

    useEffect(() => {
        if (paramTeacher !== null) {
            axios.get(`/gradebook/teachers?fio=${paramTeacher}`)
                .then(response => {
                    setGradebookTeacher(response.data);
                })
                .catch(_error => {
                    dispatch(setMessage("warning", "Ошибка при получении преподавателя"))
                })
        }
    }, [paramTeacher])

    useEffect(() => {
        if (gradebookTeacher.length !== 0 && selectedTeacher === null) {
            selectTeacher(gradebookTeacher[0])
        }
    }, [gradebookTeacher])

    const getSelectedSemester = () => {
        return getSemesters()[selectedSemesterIndex];
    }

    const getTableParams = () => {
        let employeeId;
        if ((user.authorities.includes("ROLE_ADMIN") || user.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) && selectedTeacher !== null) {
            employeeId = selectedTeacher.id;
        } else {
            employeeId = user.personId;
        }

        const semester = getSelectedSemester();
        return {
            employeeId: employeeId,
            lessonTypeId: selectedTypeLesson,
            studentGroupId: selectedGroupId,
            termHoursId: selectedTermHours,
            year: semester.year,
            semester: semester.halfOfYear.value,
        }
    }

    const loadGradeBookData = () => {
        setIsNewGradeBookData(true)
        setIsOmissionsMode(selectedTypeLesson === 2);
        
        dispatch(getGradeBookData(getTableParams()));
    }

    useEffect(() => {
        if (gradeBookData && !studentPhotosLoaded) {
            dispatch(getStudentPhotos(gradeBookData, getTableParams()));
        }
    }, [gradeBookData, studentPhotosLoaded])

    useEffect(() => {
        if ((user.authorities.includes("ROLE_ADMIN") || user.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) && (selectedTeacher !== 0 || gradebookTeacher !== 0)) {
            if (selectedTeacher || gradebookTeacher.length !== 0) {
                setIsNewGradeBookSubjects(true)
                getSubjectFilterOptions();
            } else {
                if (paramTeacher === null) {
                    setIsNewGradeBookSubjects(true)
                    getSubjectFilterOptions();
                }
            }
        } else {
            setIsNewGradeBookSubjects(true)
            getSubjectFilterOptions();
        }

    }, [selectedSemesterIndex, selectedTeacher])

    useEffect(() => {
        if (gradeBookSubjects.length !== 0 && isNewGradeBookSubjects && !selectedGroup) {
            setIsNewGradeBookSubjects(false)
            const subject = gradeBookSubjects[0].name
            const lessonType = gradeBookSubjects[0].lessonTypes[0].lessonTypeId
            const defaultStudentGroup =  gradeBookSubjects[0].lessonTypes[0].studentGroups[0]   
            setSelectedGroup(`${subject}_${lessonType}_${defaultStudentGroup.id}_${defaultStudentGroup.term}`)
            setSelectedTermHours(defaultStudentGroup.termHoursId)
            setIsCalculatedLabCount(true)
            setSelectedLabCount(defaultStudentGroup.labCount)
            onReset()
        }
    }, [gradeBookSubjects])

    useEffect(() => {
        if (gradeBookSubjects.length !== 0 && selectedGroup && selectedTypeLesson) {
            const studGroup = gradeBookSubjects.find(sub => sub.name === selectedSubject)?.lessonTypes.find(type => type.lessonTypeId === selectedTypeLesson).studentGroups.find(gr => gr.id === selectedGroupId);
            setSelectedGroupName(studGroup?.name);
            setFilterSubGroups(subgroups.filter(subGr => subGr.key <= (studGroup?.subgroupsCount || 0)));
        } else {
            setSelectedGroupName(null);
            setFilterSubGroups(subgroups);
        }
    }, [selectedGroup])

    const getSubjectFilterOptions = () => {
        const semester = getSelectedSemester();
        dispatch(getGradeBookSubjects(semester.year, semester.halfOfYear.value, selectedTeacher?.id));
    }

    const checkLab = () => {
        return !isLab() || isLabAndCountSet()
    }

    const isLabAndCountSet = () => {
        return isLab() && selectedLabCount !== null
    }

    const onReset = () => {
        setGradeBookFilterStudents([])
        setGradeBookFilterLessons([])
        setLabEditButtonState(true)
        setGradeSettingValue(null)
        setIsGradeSettingMode(false)
        setIsOpenLabDialog(false)
        setGradeBookStudents([])
    }

    const selectSemester = (semester) => {
        if (semester === undefined || semester === null) {
            setSelectedSemesterIndex(getDefaultSemesterIndex())
        } else {
            setSelectedSemesterIndex(semester)
        }
        setSelectedGroup(null)
        setSelectedTermHours(null)
        setSelectedLabCount(null)
        setSelectedSubgroup(null)
        onReset()
    }

    const selectGroup = (groupValue) => {
        setSelectedGroup(groupValue)
        if (groupValue === undefined) {
            setSelectedTermHours(null)
            setSelectedLabCount(null)

        } else {
            let [subjectName, lessonTypeId, groupId] = groupValue.split('_')
            lessonTypeId = parseInt(lessonTypeId)
            groupId = parseInt(groupId)

            const studentGroup = gradeBookSubjects.find(sub => sub.name === subjectName).lessonTypes.find(type => type.lessonTypeId === lessonTypeId).studentGroups.find(stg => stg.id === groupId);
            setSelectedTermHours(studentGroup.termHoursId);
            setIsCalculatedLabCount(true)
            setSelectedLabCount(studentGroup.labCount)
        }
        setSelectedSubgroup(null)
        onReset()
    }

    const selectSubgroup = (subgroup) => {
        if (subgroup === undefined) setSelectedSubgroup(null)
        else setSelectedSubgroup(subgroup)
    }

    const searchTeacher = (teacher) => {
        if (teacher === undefined) setSearchValueOfTeacher(null)
        else setSearchValueOfTeacher(teacher)
    }

    const separatedFio = () => {
        let fioArray = user.fio.split(' ')
        return getFio(fioArray[0], fioArray[1], fioArray[2])
    }

    const setTeacherToFilter = (teacher) => {
        setSelectedGroup(null)
        setSelectedTermHours(null)
        setSelectedLabCount(null)
        setSelectedSubgroup(null)
        onReset()

        if (teacher === undefined) {
            selectTeacher(null);
        } else {
            const selectedTeacherInGradebookTeachers = gradeBookTeachers.find(t => (t.lastName + " " + t.firstName[0] + ". " + (t.middleName ? (t.middleName[0] + ".") : '')) === teacher);
            selectTeacher(selectedTeacherInGradebookTeachers)
        }

    }

    const noActualGradebookData = () => {
        return !gradeBookData || loadingGradeBook;
    }
    
    const getValueForTeacherSelector = () => {
        if(selectedTeacher) {
            return {key: selectedTeacher.id, value: selectedTeacher.lastName + " " + selectedTeacher.firstName[0] + ". " + (selectedTeacher.middleName ? (selectedTeacher.middleName[0] + ".") : '')}
        }
        else {
            return separatedFio();
        }
    }

    const getOptionsForTeachers = () => {
        return gradeBookTeachers.length !== 0 ? gradeBookTeachers.map((teacher) => ({ key: teacher.id, value: teacher.lastName + " " + teacher.firstName[0] + ". " + (teacher.middleName ? (teacher.middleName[0] + ".") : '' )}))
                                            : null
    }

    const contentNotFoundForTeachers = () => {
        return loadingGradeBookTeachers
            ? <Spin size="small"/>
            : <div style={{color: '#bfbfbf', padding: '6px'}}>Совпадения не найдены.</div>
    }

    const isLab = () => {
        return isLabId(selectedTypeLesson);
    }

    const isLabId = (lessonTypeId) => {
        return lessonTypeId === 4;
    }

    const areSubGroupsValid = () => {
        return (isLab() && (!gradeBookStudents.some(student => student.subgroup === null && !student.expelledInCurrentSemester) || selectedLabCount == null)) || !isLab();
    }

    const getSemesterOptions = () => {
        const semesters = getSemesters();
        const semesterYears = semesters.map(sem => sem.year);

        return semesterYears.filter((year, index) => semesterYears.indexOf(year) === index).map((year) => {
            return {
                label: `${year}/${year + 1}`,
                options: semesters.filter(sem => sem.year === year).map((sem) => ({
                    title: `${year}/${year + 1} - ${sem.halfOfYear.name.toLowerCase()}`,
                    label: sem.halfOfYear.name,
                    key: `${year}${sem.halfOfYear.key}`,
                    value: semesters.indexOf(sem),
                }))
            }
        })
    }
    const getLessonOptions = (subject, lessonType, studentGroups) => {
        const subjectName = subject.name;
        const lessonTypeUpperCase = lessonType.lessonType.toUpperCase();
        return studentGroups.map(item => {
            const itemName = item.name;
            const studentGroupLabel = itemName + (studentGroups.some(sg => item !== sg && itemName === sg.name) ? ` (${item.term})` : '');
            const title = `${subjectName} (${lessonTypeUpperCase}) - ${studentGroupLabel}`;
            const value = `${subjectName}_${lessonType.lessonTypeId}_${item.id}_${item.term}`;
            const filterValue = `${subjectName} (${lessonTypeUpperCase}) - ${itemName}\n${subject.fullName}\n${lessonType.fullLessonTypeName}`;
            return {
                label: studentGroupLabel,
                title: title,
                value: value,
                filterValue: filterValue
            };
        });
    };

    const groupOptionList = useMemo(() => {
        const options = [];
        for (let subject of gradeBookSubjects) {
            for (let lessonType of subject.lessonTypes) {
                const lessonOptions = getLessonOptions(subject, lessonType, lessonType.studentGroups);
                if (lessonOptions.length !== 0) {
                    const groupLessonOptions = { label: `${subject.name} - ${lessonType.lessonType.toLowerCase()}`, options: lessonOptions };
                    if (subject.name === selectedSubject && lessonType.lessonTypeId === selectedTypeLesson) {
                        options.unshift(groupLessonOptions);
                    } else {
                        options.push(groupLessonOptions);
                    }
                }
            }
        }

        return options;
    }, [gradeBookSubjects, selectedSubject, selectedTypeLesson]);


    return <div className={classes.container}>

        {<div className={classes.filtersContainer}>
            <div className={classes.filtersPartContainer}>
                <div className={classes.filtersWithAlertContainer}>
                {
                    (user?.authorities.includes("ROLE_ADMIN") || user?.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) &&
                    <>
                        <PiChalkboardTeacher className={classes.teacherIcon}/>
                        <div className={classes.filterSelectContainer}>
                            <div className={classes.filterSelectName}
                                style={{marginBottom: '10px'}}>Преподаватель
                            </div>
                            <Select
                                className={classes.teacherSelector}
                                placeholder="Преподаватель"
                                showSearch={true}
                                options={getOptionsForTeachers()}
                                notFoundContent={contentNotFoundForTeachers()}
                                onSearch={searchTeacher}
                                onSelect={(e) => setTeacherToFilter(e)}
                                value={getValueForTeacherSelector()}
                            />
                        </div>
                    </>
                }
                <div className={classes.filterSelectContainer}>
                    <div className={classes.filterSelectName} style={{marginBottom: '10px'}}>Семестр</div>
                    <GroupedSelector
                        className={classes.filterSelector}
                        placeholder="Семестр"
                        groupedOptions={getSemesterOptions()}
                        notFoundContent={<div>Совпадения не найдены.</div>}
                        onChange={selectSemester}
                        value={selectedSemesterIndex}
                    />
                </div>
                <div className={classes.filterSelectContainer}>
                    <div className={classes.filterSelectName} style={{marginBottom: '10px'}}>Группа</div>
                    <GroupedSelector
                        className={classes.filterSelector}
                        placeholder="Группа"
                        groupedOptions={groupOptionList}
                        notFoundContent={loadingGradeBookSubjects ? <Spin size="small"/> :
                            <div>Совпадения не найдены.</div>}
                        onChange={selectGroup}
                        value={selectedGroup}
                        disabled={groupOptionList.length === 0}
                        showSearch={true}
                    />
                </div>
                </div>
            {
                !areSubGroupsValid() &&
                getSubGroupMessage()
            }
            </div>
            {
                gradeBookSubjects.length !== 0 &&
                filterSubGroups &&
                filterSubGroups.length !== 0  &&
                !(filterSubGroups.length === 1 && filterSubGroups[0].key === 0) &&
                <div className={classes.subgroupSelectContainer}>
                    <div className={classes.filterSelectName} style={{marginBottom: '10px'}}>Подгруппа</div>
                    <Select
                        className={classes.filterSelector}
                        placeholder="Подгруппа"
                        options={filterSubGroups.map(item => ({value: item.key, label: item.name}))}
                        onChange={selectSubgroup}
                        value={selectedSubgroup}
                        disabled={noActualGradebookData() || !checkLab()}
                    />
                </div>
            }
        </div>}
        <JournalPanel
            tableViewSettingsCookies={tableViewSettingsCookies}
            setTableViewSettingsCookies={setTableViewSettingsCookies}
            displaySubgroupSetting={displaySubgroupSetting}
            setDisplaySubgroupSetting={setDisplaySubgroupSetting}
        
            gradeBookStudents={gradeBookStudents}
            setGradeBookStudents={setGradeBookStudents}
            gradeBookLessons={gradeBookLessons}
            filterSubGroups={filterSubGroups}
            gradeBookFilterStudents={gradeBookFilterStudents}
            gradeBookFilterLessons={gradeBookFilterLessons}
        
            selectedSemesterIndex={selectedSemesterIndex}
            selectedSubject={selectedSubject}
            selectedTypeLesson={selectedTypeLesson}
            selectedGroupId={selectedGroupId}
            selectedGroupName={selectedGroupName}
            selectedTermHours={selectedTermHours}
            selectedSubgroup={selectedSubgroup}
            selectedTeacher={selectedTeacher}
            isCalculatedLabCount={isCalculatedLabCount}
            areSubGroupsValid={areSubGroupsValid}

            isOmissionsMode={isOmissionsMode}
            setIsOmissionsMode={setIsOmissionsMode}
            isGradeSettingMode={isGradeSettingMode}
            setIsGradeSettingMode={setIsGradeSettingMode}
            gradeSettingValue={gradeSettingValue}
            setGradeSettingValue={setGradeSettingValue}
            isOpenLabDialog={isOpenLabDialog}
            setIsOpenLabDialog={setIsOpenLabDialog}
            selectedLabCount={selectedLabCount}
            setSelectedLabCount={setSelectedLabCount}
            labEditButtonState={labEditButtonState}
            setLabEditButtonState={setLabEditButtonState}
            loadGradeBookData={loadGradeBookData}
        />
    </div>
}

export default ProfileJournalDesktop