import {Avatar, Button, Form, Modal, Popconfirm, Table} from "antd";
import classes from './EditCellDialog.module.scss';
import React, {useEffect, useState} from "react";
import {AiOutlineUser} from "react-icons/ai";
import {getFormattedDateStringFromTimestamp} from "../../../../services/dateUtils";
import {TbEdit, TbPlus} from "react-icons/tb";
import {IoCheckmarkOutline, IoCloseOutline} from "react-icons/io5";
import {MdDeleteOutline} from "react-icons/md";
import EditableCell from "./EditableCell/EditableCell";
import {cloneDeep} from "lodash";

const EditCellDialog = (props) => {

    const [form] = Form.useForm()
    const [editStudent, setEditStudent] = useState(props.editStudent)
    const [studentLessons, setStudentLessons] = useState(props.editStudentLessons)

    const [editingRow, setEditingRow] = useState(null)
    const [newRowKey, setNewRowKey] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setEditStudent(props.editStudent)
    }, [props.editStudent])

    useEffect(() => {
        setStudentLessons(props.editStudentLessons)
        setIsLoading(false)
    }, [props.editStudentLessons])

    const mergeColumnsProps = (row) => {
        if (row.isRowDate) return {colSpan: 0}
        else return {}
    }

    const mergeRowsProps = (row) => {
        if (row.isRowDate) return mergeColumnsProps(row)
        else {
            if (row.isFirstMark !== undefined) {
                if (row.isFirstMark) return {rowSpan: row.lengthMarksDate}
                if (!row.isFirstMark && row.lengthMarksDate !== 1) return {rowSpan: 0}
            } else return {}
        }
    }

    const isEditing = (row) => editingRow && (row.key === editingRow.key || row.key === 'newRowDate')

    const createEmptyRow = () => {
        form.resetFields()
        let studentLessonsTmp = cloneDeep(studentLessons)

        let newRow = [
            {
                date: props.dates[0],
                isRowDate: true,
                key: 'newRowDate',
                mark: null,
                omission: null
            },
            {
                date: props.dates[0],
                isFirstMark: false,
                isRowDate: false,
                key: 'newRow',
                lengthMarksDate: 1,
                mark: null,
                omission: null
            }
        ]

        studentLessonsTmp.unshift(...newRow)
        setStudentLessons(studentLessonsTmp)
        setNewRowKey('newRow')
        setEditingRow(studentLessonsTmp[1])
    }

    const editRow = (row) => {
        form.setFieldsValue({
            mark: row.mark ? row.mark.number : null,
            markLabNumber: row.mark ? row.mark.labNumber : null,
            markNote: row.mark ? row.mark.note : null,
            omission: row.omission ? row.omission.number : null,
            omissionNote: row.omission ? row.omission.note : null,
        })
        setEditingRow(row)
    }

    const isDeletable = (row) => {
        const currentDate = new Date()
        const selectedDate = new Date(row?.date?.date);
        selectedDate?.setHours(23, 59, 59, 999)
        selectedDate?.setDate(selectedDate.getDate() + 1)
        return currentDate > selectedDate
    }

    const deleteRow = (row) => {
        if (studentLessons.filter(les => les.date.id === row.date.id && !les.isRowDate).length > 1) {
            deleteMark(row.mark.id, row.date.id)
        } else {
            if (row.mark) deleteMark(row.mark.id, row.date.id)
            if (row.omission) updateOmission(row.date.id, {omission: 0})
        }
    }

    const saveNewRow = () => {
        form.validateFields()
            .then(values => {
                if (values.mark !== null && values.mark !== undefined) {
                    saveMark(values.newDate, values)
                }
                if (values.omission) {
                    updateOmission(values.newDate, values)
                }

                form.resetFields()
                setEditingRow(null)
                setNewRowKey(null)
            })
            .catch(_ => console.log('fields is empty'))
    }

    const saveRow = (row) => {
        if (newRowKey === 'newRow') {
            saveNewRow()
        } else {
            let formValues = form.getFieldsValue()
            if (props.isPercentage || (formValues.mark !== null && formValues.mark !== undefined && row.mark)) {
                updateMark(row.mark, row.date.id, formValues)
            } else if (formValues.mark !== null && formValues.mark !== undefined) {
                saveMark(row.date.id, formValues)
            } else if (row.mark) deleteMark(row.mark.id, row.date.id)

            const currentOmission = row.omission;
            if (!currentOmission || formValues.omission && (formValues.omission !== currentOmission.number || formValues.omissionNote !== currentOmission.note)
                || !formValues.omission && currentOmission) {
                updateOmission(row.date.id, formValues)
            }

            form.resetFields()
            setEditingRow(null)
            setNewRowKey(null)
        }
    }

    const cancelEditRow = () => {
        if (newRowKey === 'newRow') {
            setStudentLessons(props.editStudentLessons)
            setNewRowKey(null)
        }
        setEditingRow(null)
    }

    const saveMark = (idDate, formValues) => {
        setIsLoading(true)
        let params = {
            mark: formValues.mark,
            lessonId: idDate,
            note: formValues.markNote && formValues.markNote.length !== 0 ? formValues.markNote : null,
            studentLabNumberDtoList: [{
                studentId: editStudent.key,
                labNumber: formValues.markLabNumber
            }],
        }
        props.saveMarkRequest(params, props.dates.findIndex(date => date.id === idDate))
    }

    const updateMark = (mark, idDate, formValues) => {
        setIsLoading(true)
        let params = {
            id: mark.id,
            number: props.isPercentage ? mark.number : formValues.mark,
            note: formValues.markNote && formValues.markNote.length !== 0 ? formValues.markNote : null,
            labNumber: formValues.markLabNumber
        }
        props.updateMarkRequest(params, props.dates.findIndex(date => date.id === idDate), editStudent)
    }

    const deleteMark = (idMark, idDate) => {
        setIsLoading(true)
        props.deleteMarkRequest(idMark, props.dates.findIndex(date => date.id === idDate), editStudent)
    }

    const updateOmission = (idDate, formValues) => {
        setIsLoading(true)
        let params = {
            missedHours: formValues.omission || 0,
            lessonId: idDate,
            studentIds: [editStudent.key],
            note: formValues.omissionNote && formValues.omissionNote.length !== 0 ? formValues.omissionNote : null
        }
        props.updateOmissionRequest(params, props.dates.findIndex(date => date.id === idDate))
    }

    const getMarkLabNumberColumn = () => {
        let tmp = []
        if (props.isLab)
            tmp.push({
                title: '№ лр',
                dataIndex: 'mark',
                key: 'markLabNumber',
                width: '10%',
                align: 'center',
                editable: true,
                onCell: mergeColumnsProps,
                render: (mark) => <div>
                    {mark && mark.labNumber ? `${mark.labNumber}` : ''}
                </div>
            })
        return tmp
    }

    const columns = [
        {
            isOkForPercentage: true,
            title: props.isPercentage ? 'Проценты' : 'Оценки',
            dataIndex: 'mark',
            key: 'mark',
            width: props.isPercentage ? '22%' : '12%',
            align: 'center',
            editable: !props.isPercentage,
            onCell: (row) => {
                let styleProps = {
                    style: {
                        backgroundColor: row.isRowDate && row.key !== 'newRowDate' ? '#fafafa' : 'default'
                    }
                }
                if (row.key === 'newRowDate') return {...styleProps, colSpan: props.isLab ? 5 : 4}
                else if (row.isRowDate) return {...styleProps, colSpan: props.isLab ? 6 : 5}
                else return styleProps
            },
            render: (mark, row) => {
                let markCell = mark ? mark.number : null
                let sugGroup
                if (row.date && row.date.subgroup === 1) sugGroup = ' (1 п.)'
                else if (row.date && row.date.subgroup === 2) sugGroup = ' (2 п.)'
                else sugGroup = ''
                return <div style={{fontWeight: row.isRowDate ? '600' : 'normal'}}>
                    {row.isRowDate ? getFormattedDateStringFromTimestamp(row.date.date) + sugGroup: markCell}
                </div>
            }
        },
        ...getMarkLabNumberColumn(),
        {
            isOkForPercentage: true,
            title: 'Примечания',
            dataIndex: 'mark',
            key: 'markNote',
            width: props.isPercentage ? '51%' : '28%',
            align: 'center',
            editable: true,
            onCell: mergeColumnsProps,
            render: (mark) => <div>
                {mark && mark.note ? `${mark.note}` : ''}
            </div>
        },
        {
            title: 'Часы',
            dataIndex: 'omission',
            key: 'omission',
            width: '10%',
            align: 'center',
            editable: true,
            onCell: mergeRowsProps,
            render: (omission) => <div style={{color:omission?.respectfulOmission  ?'green':'red'}}>
                {omission ? `${omission.number} ч.` : ''}
            </div>
        },
        {
            title: 'Примечания',
            dataIndex: 'omission',
            key: 'omissionNote',
            width: '25%',
            align: 'center',
            editable: true,
            onCell: mergeRowsProps,
            render: (omission) => <div>
                {omission && omission.note ? `${omission.note}` : ''}
            </div>
        },
        {
            isOkForPercentage: true,
            title: 'Управление',
            dataIndex: 'actions',
            key: 'actions',
            width: props.isPercentage ? '27%' : '15%',
            align: 'center',
            onCell: (row) => {
                if (row.key === 'newRowDate') return {rowSpan: 2}
                else if (row.key === 'newRow') return {rowSpan: 0}
                else return mergeColumnsProps(row)
            },
            render: (_, row) => {
                const editable = isEditing(row)
                return editable
                    ? <div>
                        <Button type={"link"} icon={<IoCheckmarkOutline color={'#2BAD45'} size={25}/>} onClick={() => saveRow(row)}/>
                        <Button type={"link"} danger icon={<IoCloseOutline size={25}/>} onClick={() => cancelEditRow()}/>
                    </div>
                    : <div>
                        <Button type={"link"} icon={<TbEdit size={25}/>} onClick={() => editRow(row)} disabled={newRowKey !== null}/>
                        <Popconfirm title="Удалить?" onConfirm={() => deleteRow(row)} disabled={newRowKey !== null || editingRow !== null}>
                            <Button type={"link"} danger icon={<MdDeleteOutline size={25}/>} disabled={newRowKey !== null || editingRow !== null || isDeletable(row)}/>
                        </Popconfirm>
                    </div>
            },
        }
    ].filter(item => !props.isPercentage || item.isOkForPercentage)

    const mergedColumns = columns.map((column) => {
        if (!column.editable) {
            return column
        }
        return {
            ...column,
            onCell: (row) => ({
                form,
                editStudent,
                allLabNumbers: props.allLabNumbers,
                initialDate: props.editDate.id,
                dates: props.dates,
                columnName: row.key === 'newRowDate' ? 'newDate' : column.key,
                editing: isEditing(row) || (newRowKey !== 'newRow' && editingRow && row.date.id === editingRow.date.id && (column.key === 'omission' || column.key === 'omissionNote')),
                dateOmission: row.date.date,
                editJournal: props.editJournal,
                newRowKey: newRowKey,
                tableData: studentLessons,
                ...column.onCell(row)
            })
        }
    })

    return <Modal
        title='Информация о студенте'
        open={true}
        centered={true}
        width={700}
        bodyStyle={{height: "520px", padding: "10px 24px"}}
        onCancel={props.onClose}
        footer={null}
    >
        <div className={classes.modalBody}>
            <div className={classes.studentContainer}>
                <Avatar
                    src={editStudent.photo}
                    size={70}
                    icon={<AiOutlineUser/>} alt="profileIcon"
                    className={classes.studentAvatar}
                />
                <div>
                    <div>{editStudent.fio}, гр.{props.selectedGroupName}</div>
                    <div className={classes.studentContact}>{editStudent.email}</div>
                </div>
                {
                    !props.isPercentage &&
                    <Button className={classes.addButton}
                            style={{alignSelf: 'end', marginLeft: 'auto'}}
                            icon={<TbPlus size={18} style={{marginRight: '5px'}}/>}
                            disabled={newRowKey !== null}
                            onClick={() => createEmptyRow()}>
                        Выставить
                    </Button>
                }
            </div>
            <div className={classes.tableContainer}>
                <Form form={form} component={false}>
                    <Table className={`${classes.table} ${studentLessons.length === 0 ? classes.emptyTable : ''}`}
                           columns={mergedColumns}
                           sticky
                           loading={isLoading}
                           pagination={false}
                           bordered={true}
                           scroll={{y: '365px'}}
                           dataSource={studentLessons}
                           components={{
                               body: {
                                   cell: EditableCell,
                               }
                           }}
                    />
                </Form>
            </div>
        </div>
    </Modal>
}

export default EditCellDialog