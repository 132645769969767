import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Placeholder from 'react-bootstrap/Placeholder'
import {Card} from 'react-bootstrap';
import DefaultIcon from '../../assets/images/default_photo.png'
import classes from './PersonalPage.module.scss';

import {
    addPhoneRequest,
    deletePhoneRequest,
    deleteProfileLinkRequest,
    getEmployeeDetails,
    getFormerJobPositions,
    getPhones,
    saveProfileLinkRequest,
    setEmployeeDetails,
    setFormerJobPositions,
    updateAdditionalInformationRequest
} from "../../store/actions/employee";

import {Button, Modal, Select, Spin, Tooltip} from "antd";
import {BsArrowRightCircle} from "react-icons/bs";
import {FaPlus} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import AdditionalInformation from "./AdditionalInformation/AdditionalInformation";
import {setMessage} from "../../store/actions";
import ProfileLinks from "./ProfileLinks/ProfileLinks";
import ProfilePhotoInput from "../ProfileSettings/ProfilePhotoInput/ProfilePhotoInput";
import NotFound from "../UI/NotFound/NotFound";

const PersonalPage = () => {
    const dispatch = useDispatch();
    const employeeDetails = useSelector(state => state.employee.employeeDetails)
    const loadingEmployeeDetails = useSelector(state => state.employee.loadingEmployeeDetails)
    const phones = useSelector(state => state.employee.phones)
    const loadingPhones = useSelector(state => state.employee.loadingPhones)
    const formerJobPositions = useSelector(state => state.employee.formerJobPositions)
    const loadingFormerJobPositions = useSelector(state => state.employee.loadingFormerJobPositions)

    const [isAddPhoneDialogOpen, setIsAddPhoneDialogOpen] = useState(false)
    const [phone, setPhone] = useState(null)
    const [jobPosition, setJobPosition] = useState({value: null})
    const {confirm} = Modal;

    useEffect(() => {
        dispatch(getEmployeeDetails())
        dispatch(getPhones())
        dispatch(getFormerJobPositions())
    }, [])

    const hasContacts = () => {
        if (employeeDetails.email !== null) return true
        for (const element of employeeDetails.jobPositions) {
            if (element.contacts.length !== 0) return true
        }
        return false
    }

    const getFormattedPhoneNumber = (phoneNumber) => {
        return phoneNumber.substr(0, 4) + ' (' + phoneNumber.substr(4, 2) + ') '
            + phoneNumber.substr(6, 3) + '-' + phoneNumber.substr(9, 2) + '-' + phoneNumber.substr(11, 2);
    }

    const updateProfileLinks = (link) => {
        dispatch(saveProfileLinkRequest(link, employeeDetails))
    }

    const deleteProfileLinks = (link) => {
        dispatch(deleteProfileLinkRequest(link.profileLinkEmployeeId))
    }

    const updateAdditionalInformation = (dto, newAdditionalInformation) => {
        dispatch(updateAdditionalInformationRequest(dto, newAdditionalInformation))
    }

    const phonesNotFound = () => {
        return loadingPhones ? <Spin size="small"/> : <div>Совпадения не найдены.</div>
    }

    const handleFilterOption = (inputValue, option) => {
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    }

    const handleChangePhone = (value) => {
        value ? setPhone(value) : setPhone(null)
    }

    const handleChangeJobPosition = (value) => {
        value ? setJobPosition(value) : setJobPosition(null)
    }

    const openAddPhoneDialog = () => {
        setJobPosition({value: employeeDetails.jobPositions[0]?.employeeDepartmentId})
        setIsAddPhoneDialogOpen(true)
    }

    const closeAddPhoneDialog = () => {
        setPhone(null)
        setJobPosition(null)
        setIsAddPhoneDialogOpen(false)
    }

    const deletePhone = (position, contact) => {
        const updatedEmployeeDetails = {...employeeDetails};
        const updatedJobPosition = {...position};

        updatedJobPosition.contacts = updatedJobPosition.contacts.filter((item) =>
            item.phoneId !== contact.phoneId)

        updatedEmployeeDetails.jobPositions = updatedEmployeeDetails.jobPositions.map(
            (currentPosition) => (updatedJobPosition.employeeDepartmentId === currentPosition.employeeDepartmentId ? updatedJobPosition : currentPosition)
        );

        const updatedFormerJobPositions = formerJobPositions.map(currentPosition =>
            updatedJobPosition.employeeDepartmentId === currentPosition.employeeDepartmentId ? updatedJobPosition : currentPosition
        )

        dispatch(deletePhoneRequest(updatedJobPosition))
        dispatch(setEmployeeDetails(updatedEmployeeDetails))
        dispatch(setFormerJobPositions(updatedFormerJobPositions))
    }

    const updatePhones = () => {
        const updatedEmployeeDetails = {...employeeDetails}

        const currentJobPosition = updatedEmployeeDetails.jobPositions.find(
            (position) => jobPosition.value === position.employeeDepartmentId
        );

        const updatedJobPosition = {...currentJobPosition};

        if (updatedJobPosition.contacts.find((contact) => contact.phoneId === phones[phone.value].phoneId)) {
            dispatch(setMessage({type: 'warning', text: "Такой номер телефона уже привязан."}))
            return;
        }
        updatedJobPosition.contacts = updatedJobPosition.contacts.concat(phones[phone.value]);

        updatedEmployeeDetails.jobPositions = updatedEmployeeDetails.jobPositions.map(
            (position) => (jobPosition.value === position.employeeDepartmentId ? updatedJobPosition : position)
        );

        dispatch(addPhoneRequest(updatedJobPosition))
        dispatch(setEmployeeDetails(updatedEmployeeDetails))
        closeAddPhoneDialog()
    }

    const GetRankAndDegree = (props) => {
        return <div style={props.employeeDetails.degree !== '' ? {marginBottom: 15} : null} className={classes.fullWidth}>
            {(props.employeeDetails.degree !== '')
                ? <div className={classes.sectionTitle}><b>Ученая степень</b></div>
                : null}
            <div style={{fontSize: 14}}>
                {props.employeeDetails.degree !== '' ? props.employeeDetails.degree : ''}
                {(props.employeeDetails.degree !== '' && props.employeeDetails.rank) ? ', ' : ''}
                {props.employeeDetails.rank ? props.employeeDetails.rank : ''}
            </div>
        </div>
    }

    const GetJobPositionTitle = (props) => {
        return <div className={classes.fullWidth}>
            {(props.employeeDetails.jobPositions && props.employeeDetails.jobPositions.length !== 0)
                ? <div className={classes.sectionTitle}><b>Должность, место работы</b></div>
                : null}</div>
    }

    const GetOneJobPosition = ({jobPosition, department, index}) => {
        if (jobPosition !== "" && department !== "") {
            return <div key={index}>{jobPosition + ', ' + department}</div>
        } else if (jobPosition !== "" && department === "") {
            return <div key={index}>{jobPosition}</div>
        } else if (jobPosition === "" && department !== "") {
            return <div key={index}>{department}</div>
        } else {
            return null;
        }
    }

    const GetListOfJobPositions = ({jobPosition, department, index}) => {
        if (jobPosition !== "" && department !== "") {
            return <li key={index}>{jobPosition + ', ' + department}</li>
        } else if (jobPosition !== "" && department === "") {
            return <li key={index}>{jobPosition}</li>
        } else if (jobPosition === "" && department !== "") {
            return <li key={index}>{department}</li>
        } else {
            return null;
        }
    }

    const GetJobPositions = (props) => {
        return <div style={{fontSize: 14}}>
            {props.employeeDetails.jobPositions.length === 1
                ? <div className={classes.fullWidth}>
                    {props.employeeDetails.jobPositions.map((el, index) =>
                        <GetOneJobPosition jobPosition={el.jobPosition}
                            department={el.department}
                            index={index}/>
                    )}
                </div>
                : <div>
                    <ul>
                        {props.employeeDetails.jobPositions.map((el, index) =>
                            <GetListOfJobPositions jobPosition={el.jobPosition}
                                department={el.department}
                                index={index}/>
                        )}
                    </ul>
                </div>
            }
        </div>
    }

    const showDeletePhoneDialog = (position, contact) => {
        return confirm({
            title: 'Вы действительно хотите удалить данный номер телефона?',
            centered: true,
            okText: 'Удалить',
            okType: 'danger',
            cancelText: 'Отмена',
            onOk() {
                return deletePhone(position, contact)
            },
            width: 510
        });
    };

    return !(JSON.parse(localStorage.getItem("user")).serviceUser) ? <div className={classes.centeredContainer}>
        {loadingEmployeeDetails || loadingFormerJobPositions || !employeeDetails
            ? <React.Fragment>
                <div className={classes.header}>
                    <Card style={{width: '100%', height: 'calc(100% - 70px)'}}>
                        <Card.Img variant="text-center" src={DefaultIcon} className={classes.imageContainer}/>
                        <Card.Body>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={6}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                                <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={8}/>{' '}
                                <Placeholder xs={6}/> <Placeholder xs={8}/>
                                <Placeholder xs={9}/> <Placeholder xs={8}/>
                                <Placeholder xs={4}/> <Placeholder xs={8}/>
                            </Placeholder>
                        </Card.Body>
                    </Card>
                </div>
            </React.Fragment>
            : <div>
                <div className={classes.header}>
                    <div>{employeeDetails.lastName + ' ' + employeeDetails.firstName + ' ' + employeeDetails.middleName}</div>
                    <Tooltip title="Профиль на ИИС">
                        <a href={"https://iis.bsuir.by/employees/" + employeeDetails.urlId} target="_blank"
                            rel="noreferrer">
                            <Button type={"link"}
                                style={{padding: 0}}
                                icon={<BsArrowRightCircle size={22}/>}/>
                        </a>
                    </Tooltip>
                </div>
                <ProfilePhotoInput/>
                <div className={classes.details}>
                    <div className={classes.info}>

                        <div className={classes.jobPosition}>
                            <div className={classes.personalInformationContainer}>
                                <GetRankAndDegree employeeDetails={employeeDetails}/>
                                <GetJobPositionTitle employeeDetails={employeeDetails}/>
                                <div>
                                    {(employeeDetails.jobPositions && employeeDetails.jobPositions.length !== 0)
                                        ? <GetJobPositions employeeDetails={employeeDetails}/>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <div className={classes.contactsGrid}>
                            {(hasContacts())
                                ? <React.Fragment>
                                    <div className={classes.fullWidth}>
                                        <div className={classes.sectionTitle}>Контакты</div>
                                        <div style={{fontSize: 14}}>
                                            {employeeDetails.email
                                                && <div>Почта: <a href={`mailto:${employeeDetails.email}`}
                                                    className={classes.underline}>{employeeDetails.email}</a>
                                                </div>}
                                            <div style={{width: "100%"}}>
                                                Рабочий телефон:
                                                <Tooltip title="Добавить номер телефона">
                                                    <Button type={"link"} className={classes.addButton}
                                                        icon={<FaPlus size={18} style={{marginTop: "5px"}}
                                                            onClick={openAddPhoneDialog}/>}></Button>
                                                </Tooltip>

                                                {employeeDetails.jobPositions
                                                    .concat(formerJobPositions)
                                                    .map((position) =>
                                                        <ul style={{paddingLeft: "30px"}}>
                                                            {position.contacts.map((contact) =>
                                                                <li className={classes.contact} key={contact.phoneNumber}>
                                                                    <div style={{width: "100%", marginTop: "-7px"}}>
                                                                        <a href={`tel: ${contact.phoneNumber}`}
                                                                            className={classes.underline}>{getFormattedPhoneNumber(contact.phoneNumber)}</a>
                                                                        <Tooltip title="Удалить" placement={"bottom"}>
                                                                            <Button type={"link"} className={classes.addButton}
                                                                                icon={<MdDelete size={18}
                                                                                    style={{marginTop: "5px"}}
                                                                                    onClick={() => showDeletePhoneDialog(position, contact)}/>}></Button>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div style={{
                                                                        marginTop: "-5px",
                                                                        marginBottom: "10px",
                                                                        width: "100%"
                                                                    }}>{"каб. " + contact.auditory + "-" + contact.buildingNumber + ", " + contact.address}</div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                    {isAddPhoneDialogOpen
                                        && <Modal title="Привязать номер телефона"
                                            open={true}
                                            onOk={updatePhones}
                                            okButtonProps={{disabled: !phone}}
                                            onCancel={closeAddPhoneDialog}
                                            cancelText="Отменить"
                                            okText="Сохранить"
                                            width={510}
                                            centered={true}>

                                            <div className={classes.selectContainer}>
                                                <div className={classes.selectName}>Должность</div>
                                                <Select className={classes.selectButton}
                                                    labelInValue
                                                    defaultActiveFirstOption={true}
                                                    placeholder='Должность'
                                                    defaultValue={employeeDetails.jobPositions[0].jobPosition + ", " + employeeDetails.jobPositions[0].department}
                                                    filterOption={false}
                                                    onChange={handleChangeJobPosition}
                                                    options={employeeDetails.jobPositions.map(item => ({
                                                        value: item.employeeDepartmentId,
                                                        label: item.jobPosition + ", " + item.department
                                                    }))}
                                                />
                                            </div>

                                            <div className={classes.selectContainer}>
                                                <div className={classes.selectName}>Номер телефона</div>
                                                <Select className={classes.selectButton}
                                                    showSearch={true}
                                                    allowClear
                                                    labelInValue
                                                    defaultActiveFirstOption={true}
                                                    placeholder='Номер телефона'
                                                    filterOption={handleFilterOption}
                                                    notFoundContent={phonesNotFound()}
                                                    onChange={handleChangePhone}
                                                    options={phones.map((item, index) => ({
                                                        value: index,
                                                        label: item.phoneNumber + " (" + item.department + ", " + item.auditory + " - " + item.buildingNumber + ")"
                                                    }))}
                                                />
                                            </div>
                                        </Modal>}
                                </React.Fragment> : null}
                        </div>
                    </div>
                    <hr style={{marginTop: 0}}/>

                    <ProfileLinks employeeDetails={employeeDetails} updateProfileLinks={updateProfileLinks} deleteProfileLinks={deleteProfileLinks}/>

                    <React.Fragment style={{marginBottom: 20}}>
                        {employeeDetails.additionalInformation.map((el, index) =>
                            <div key={index} className={classes.additionalInformation}>
                                <hr style={{margin: "10px 0"}}/>

                                <AdditionalInformation employeeDetails={employeeDetails} element={el}
                                    updateAdditionalInformation={updateAdditionalInformation}/>

                            </div>)}
                    </React.Fragment>

                </div>
            </div>
        }
    </div>
        : <NotFound />
}

export default PersonalPage;
