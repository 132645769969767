import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Input, Form, Result, Skeleton, Tag} from 'antd';
import ReactCodeInput from "react-code-input";
import { IoIosCheckmarkCircleOutline, IoIosPhonePortrait } from "react-icons/io";
import { IoAlertCircleOutline } from "react-icons/io5";

import classes from './ManageContacts.module.scss';
import parentClasses from '../ProfileSettings.module.scss';
import axios from '../../../services/axios';
import { CODE_LENGTH, BAN_DURATION } from '../../../services/constants';
import { setMessage } from '../../../store/actions';

import SubmitButton from '../../UI/SubmitButton/SubmitButton';
import CancelButton from '../../UI/CancelButton/CancelButton';
import CountdownTimer from '../../UI/CountdownTimer/CountdownTimer';

const ManageContacts = (props) => {
    const dispatch = useDispatch()

    const [loadingContacts, setLoadingContacts] = useState(false)
    const [loadingContactsError, setLoadingContactsError] = useState(false)
    const [contactsList, setContactsList] = useState(null)

    const [verificationProcessing, setVerificationProcessing] = useState(null)
    const [numberOfAttempts, setNumberOfAttempts] = useState(4)
    const [confirmationCode, setConfirmationCode] = useState('')
    const [loadingConfirmationCode, setLoadingConfirmationCode] = useState(false)
    const [isCodeExpired, setIsCodeExpired] = useState(false)
    const [isConfirmationCodeValid, setIsConfirmationCodeValid] = useState(true)
    const [codeExpirationTime, setCodeExpirationTime] = useState(null)
    const [isVerificationBlocked, setIsVerificationBlocked] = useState(false)
    const [verificationBlockExpirationTime, setVerificationBlockExpirationTime] = useState(null)

    useEffect(() => {
        const contactsExtraData = [
            {
                contactTypeId: 4,
                name: 'Мобильный телефон',
                details: 'Данный номер будет использоваться для восстановления пароля.',
                icon: <IoIosPhonePortrait />,
                placeholder: '+375 YY XXX XX XX',
                updatingContact: false,
                validationRules: {
                    pattern: /^\+375(25|29|33|44)\d{7}$/,
                    message: 'Некорректный формат телефона: +375YYXXXXXXX (YY - код оператора, XXXXXXX - 7 цифр)'
                },
                isValid: true,
            }
        ]

        setLoadingContacts(true)
        setLoadingContactsError(false)
        axios.get('/settings/contacts')
            .then((response) => {
                setNumberOfAttempts(response.data[0].remainingAttempts)
                setContactsList(response.data
                    .map(contact => ({
                        ...contact,
                        ...contactsExtraData.find(contactDetails => contactDetails.contactTypeId === contact.contactTypeId),
                        initialContactValue: contact.contactValue
                    })))
            })
            .catch(() => setLoadingContactsError(true))
            .finally(() => setLoadingContacts(false))
    }, [])

    useEffect(() => {
        if (numberOfAttempts === 0) setIsVerificationBlocked(true)
    }, [numberOfAttempts, verificationProcessing, isCodeExpired])

    const updateContactProperty = (contactTypeId, fieldName, newValue) => {
        const tmp = contactsList.map(contact => ({ ...contact }))
        tmp.find(contact => contact.contactTypeId === contactTypeId)[fieldName] = newValue
        setContactsList(tmp)
    }

    const onUpdateContactHandler = (contact) => {
        updateContactProperty(contact.contactTypeId, 'updatingContact', true)

        axios.put(`/settings/contacts/${contact.id}`, {
            contactTypeId: contact.contactTypeId,
            contactValue: contact.contactValue
        })
            .then(() => {
                const tmp = contactsList.map(contact => ({ ...contact }))
                setContactsList(tmp.map(c => {
                    if (c.contactTypeId === contact.contactTypeId) {
                        return { ...c, updatingContact: false, initialContactValue: contact.contactValue, confirmed: false}
                    }
                    return c
                }))
                dispatch(setMessage({ type: 'success', text: 'Контактные данные успешно обновлены.' }))
            })
            .catch(() => {
                updateContactProperty(contact.contactTypeId, 'updatingContact', false)
                dispatch(setMessage({ type: 'error', text: 'Не удалось обновить контактные данные.' }))
            })
    }

    useEffect(() => {
        if (confirmationCode.length === CODE_LENGTH) {
            let input = document.getElementsByClassName(classes.codeContainer);
            let button = document.getElementsByClassName(classes.codeControlsContainer)[0].children[0];
            input[0].addEventListener("keypress", function (event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                    button.click();
                }
            });
        }
    }, [confirmationCode])

    const sendConfirmationCode = (contact) => {
        setIsCodeExpired(false)
        setLoadingConfirmationCode(true)

        axios.post(`/settings/contacts/${contact.id}/send-confirm`, { contactValue: contact.contactValue })
            .then((response) => {
                setNumberOfAttempts(oldValue => oldValue - 1)
                setCodeExpirationTime(response.data.expirationTime)
                dispatch(setMessage({ type: 'success', text: `Код отправлен на указанный ${contact.name.toLowerCase()}.` }))
            })
            .catch((error) => {
                if (error?.response?.status === 429) {
                    setVerificationProcessing(null)
                    setIsVerificationBlocked(true)
                    setVerificationBlockExpirationTime(error.response.data.banExpirationTime)
                } else {
                    setIsCodeExpired(true)
                    dispatch(setMessage({ type: 'error', text: 'Не удалось отправить код подтверждения.' }))
                }
            })
            .finally(() => setLoadingConfirmationCode(false))
    }

    const onCodeExpireHandler = () => {
        setConfirmationCode('')
        setCodeExpirationTime(null)
        setIsCodeExpired(true)
        dispatch(setMessage({ type: 'error', text: "Срок действия кода подтверждения истек." }))
        if (numberOfAttempts === 0) setVerificationProcessing(null)
    }

    const onBanExpireHandler = () => {
        setVerificationBlockExpirationTime(null)
        setIsVerificationBlocked(false)
    }

    const confirmContactVerification = (contact) => {
        setNumberOfAttempts(oldValue => oldValue - 1)
        setIsConfirmationCodeValid(true)

        axios.post(`/settings/contacts/${contact.id}/confirm`, { confirmCode: confirmationCode })
            .then(() => {
                updateContactProperty(contact.contactTypeId, 'confirmed', true)
                setVerificationProcessing(null)
                dispatch(setMessage({ type: 'success', text: 'Контакт успешно подтвержден.' }))
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 409:
                        setIsConfirmationCodeValid(false)
                        dispatch(setMessage({ type: 'error', text: 'Неверный код. Пожалуйста, введите код, который Вы только что получили.' }))
                        break;
                    case 410:
                        setIsConfirmationCodeValid(false)
                        setIsCodeExpired(true)
                        dispatch(setMessage({ type: 'error', text: 'Код подтверждения истек.' }))
                        break;
                    case 429:
                        setIsCodeExpired(false)
                        setCodeExpirationTime(null)
                        setVerificationProcessing(null)
                        setIsVerificationBlocked(true)
                        setVerificationBlockExpirationTime(error.response.data.banExpirationTime)
                        break;
                    default:
                        dispatch(setMessage({ type: 'error', text: 'Не удалось создать пароль.' }))
                }
            })
    }

    const cancelContactVerification = (contact) => {
        axios.post(`/settings/contacts/${contact.id}/cancel-code`)
            .then(() => {
                setIsCodeExpired(true)
                setConfirmationCode('')
                setCodeExpirationTime(null)
                setVerificationProcessing(null)
            })
            .catch(() => dispatch(setMessage({ type: 'error', text: 'Не удалось отменить подтверждение контакта.' })))
    }

    return <div>
        {loadingContactsError && <Result
            status="error"
            title="Не удалось загрузить контактные данные" />}
        {
            (loadingContacts || !contactsList)
                ? <div>
                    {new Array(!loadingContactsError ? 1 : 0).fill(0).map((_, index) => <div key={index} >
                        <Skeleton active block />
                    </div>)}
                </div>
                : <div>
                    {contactsList.map((contact, index) => <div
                        key={index}
                        className={parentClasses.tabContainer}>

                        <div className={parentClasses.tabSideCaption}>
                            <div className={classes.contactTitle}>{contact.name}</div>
                            <div className={classes.contactStatus}>
                                {contact.confirmed
                                || (props?.confirmedPhone?.contactValue === contact.contactValue)
                                    ? <Tag icon={<IoIosCheckmarkCircleOutline size={20} />} color="success">Подтверждён</Tag>
                                    : <Tag icon={<IoAlertCircleOutline size={20} />} color="warning">Не подтверждён</Tag>}
                            </div>
                            <div>{contact.details}</div>
                        </div>

                        <div className={parentClasses.tabContent}>
                            <Form
                                name={`${contact.name}Form`}
                                className={classes.contactsForm}
                                initialValues={{ remember: false }}
                                scrollToFirstError={true}
                                onFinish={() => onUpdateContactHandler(contact)}>
                                <Form.Item
                                    name={contact.name}
                                    initialValue={contact.initialContactValue}
                                    rules={[
                                        {
                                            required: true, message: 'Данное поле не должно быть пустым!'
                                        },
                                        contact.validationRules,
                                    ]}>
                                    <Input
                                        prefix={contact.icon}
                                        placeholder={contact.placeholder}
                                        disabled={verificationProcessing?.state && contact.id === verificationProcessing?.contactId}
                                        value={contact.contactValue}
                                        onChange={(e) => updateContactProperty(contact.contactTypeId, 'contactValue', e.target.value.trim())} />
                                </Form.Item>

                                {!verificationProcessing && <Form.Item className={classes.animateAppearance}>
                                    <SubmitButton
                                        type="submit"
                                        text="Обновить"
                                        block
                                        loading={contact.updatingContact}
                                        disabled={contact.contactValue === contact.initialContactValue} />
                                </Form.Item>}
                            </Form>

                            {(!contact.confirmed && (verificationProcessing?.state && contact.id === verificationProcessing?.contactId)) &&
                                <div className={classes.verificationContainer}>
                                    {!isCodeExpired && <div className={classes.sentMessageMessage}>Сообщение с кодом подтверждения было отправлено на указанный номер.</div>}
                                    <div>
                                        <div className={classes.codeContainer} key={isCodeExpired}>
                                            <ReactCodeInput
                                                id="confirmationCode"
                                                type="number"
                                                inputMode="numeric"
                                                className={isConfirmationCodeValid ? classes.codeInput : classes.codeInputInvalid}
                                                fields={CODE_LENGTH}
                                                autoFocus={true}
                                                disabled={isCodeExpired}
                                                isValid={isConfirmationCodeValid}
                                                onChange={(value) => setConfirmationCode(value)}
                                                value={confirmationCode}
                                            />

                                            {isCodeExpired
                                                ? <React.Fragment>
                                                    <div className={classes.attemptsContainerMobile}>
                                                        {`Осталось попыток: `}
                                                        <span className={classes.errorMessage}>{numberOfAttempts}</span>
                                                    </div>
                                                    <SubmitButton
                                                        type="button"
                                                        text="Запросить повторно"
                                                        loading={loadingConfirmationCode}
                                                        onClickHandler={() => sendConfirmationCode(contact)} />
                                                </React.Fragment>
                                                : <div className={classes.timerContainer}>
                                                    {(codeExpirationTime && !isCodeExpired) &&
                                                        <CountdownTimer targetDate={codeExpirationTime} onExpireHandler={() => onCodeExpireHandler()} displayHours={false} />}
                                                </div>}
                                        </div>

                                        {!isCodeExpired && <div className={classes.attemptsContainer}>
                                            {`Осталось попыток: `}
                                            <span className={classes.errorMessage}>{numberOfAttempts}</span>
                                        </div>}
                                    </div>

                                    <div className={classes.codeControlsContainer}>
                                        <SubmitButton
                                            type="button"
                                            text="Отправить"
                                            disabled={confirmationCode.length !== CODE_LENGTH}
                                            onClickHandler={() => confirmContactVerification(contact)} />

                                        <CancelButton
                                            type="button"
                                            text="Отмена"
                                            onClickHandler={() => cancelContactVerification(contact)} />
                                    </div>
                                </div>}

                            {(!contact.confirmed && !verificationProcessing) && <React.Fragment>
                                {isVerificationBlocked
                                    ? <div className={classes.errorMessage}>
                                        <CountdownTimer
                                            extraText="Вы превысили лимит запросов. Подтверждение контакта будет доступно через"
                                            targetDate={verificationBlockExpirationTime ?? new Date(new Date().getTime() + BAN_DURATION)}
                                            onExpireHandler={() => onBanExpireHandler()} />
                                    </div>
                                    : <div className={classes.animateAppearance}>
                                        <SubmitButton
                                            type="button"
                                            text="Подтвердить"
                                            block
                                            onClickHandler={() => {
                                                setVerificationProcessing({ state: true, contactId: contact.id })
                                                sendConfirmationCode(contact)
                                            }}
                                            loading={loadingConfirmationCode}
                                            disabled={!contact.validationRules.pattern.test(contact.contactValue)}
                                        />
                                    </div>}
                            </React.Fragment>
                            }
                        </div>
                    </div>
                    )}
                </div>
        }
    </div>
}

export default ManageContacts;